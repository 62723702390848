import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { MLT, RenderChildren } from '_components';
export function Title(props) {
    return Text(MltFromProps(props, 'title'));
}
export function TitleText(props) {
    return MltFromProps(props, 'title');
}
export function MltFromProps(props, property) {
    if (!props) {
        return '';
    }
    var cs = props[property + '.cs'];
    var en = props[property + '.en'];
    if (cs || en) {
        return { cs: cs, en: en };
    }
    if (props[property]) {
        return props[property];
    }
    return undefined;
}
export function Text(text) {
    if (!text) {
        return _jsx(_Fragment, {});
    }
    if (typeof text === 'string') {
        return _jsx(_Fragment, { children: text });
    }
    return _jsx(MLT, { cs: text.cs, en: text.en });
}
export function SimpleComponentContent(props) {
    if (props.component.children && props.component.children.length > 0) {
        return RenderChildren(props.component.children);
    }
    return _jsx(FormatedText, { text: props.component.properties.text });
}
export function FormatedText(props) {
    if (!props.text) {
        return _jsx(_Fragment, {});
    }
    var isMultiline = props.text.indexOf('\n') >= 0;
    if (isMultiline) {
        var lines = props.text.split('\n');
        return (_jsx(_Fragment, { children: lines.map(function (l, index) { return (_jsx("p", { children: l }, index)); }) }));
    }
    else {
        return _jsx(_Fragment, { children: props.text });
    }
}
