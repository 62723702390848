var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import * as Components from '_components';
export function PageLayoutController(props) {
    var _a;
    var dialogCtx = Common.useSettingsDialog();
    var overlay = Common.useOverlay();
    var language = React.useContext(Common.LanguageContext);
    var module = Common.useCurrentModule();
    var user = Common.useUser();
    var responsive = Common.useResponsive();
    var _b = React.useState(''), prevContext = _b[0], setPrevContext = _b[1];
    function canUseBig() {
        return !props.preferMinimal && responsive.lg;
    }
    function toggleLanguage() {
        var targetLanguage = language.language === 'en' ? 'cs' : 'en';
        language.setLanguage(targetLanguage);
    }
    function showAccount() {
        if (user.isPublic) {
            dialogCtx.setTab(Common.SettingsTabs.Login);
        }
        else {
            dialogCtx.setTab(Common.SettingsTabs.UserInfo);
        }
    }
    function showSettings() {
        dialogCtx.setTab(Common.SettingsTabs.Theme);
    }
    function showAbout() {
        dialogCtx.setTab(Common.SettingsTabs.About);
    }
    React.useEffect(function () {
        var context = canUseBig() + window.location.href;
        if (context !== prevContext) {
            overlay.setOverlay({ type: Common.OverlayType.Null });
            setPrevContext(context);
        }
    });
    function title() {
        if (props.title) {
            return (_jsx("h1", { children: _jsx(Components.MLT, __assign({}, props.title)) }));
        }
        else if (module) {
            return (_jsx("h1", { children: _jsx(Components.MLT, { text: module === null || module === void 0 ? void 0 : module.name }) }));
        }
        else {
            return _jsx("h1", { children: "MichalF.cz" });
        }
    }
    function toggleMenuOverlay() {
        if (overlay.overlay.type === Common.OverlayType.Navigation) {
            overlay.setOverlay({ type: Common.OverlayType.Null });
        }
        else {
            overlay.setOverlay({ type: Common.OverlayType.Navigation });
        }
    }
    return (_jsxs(Components.Widget, { children: [_jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Top, type: Components.WidgetSideType.Big, children: _jsxs(Components.Layout, { type: Components.LayoutType.HorizontalStack, children: [_jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Main, children: canUseBig() || (_jsx(Components.BtnGroup, { children: _jsx(Components.Button, { onClick: function () { return toggleMenuOverlay(); }, children: "Menu" }) })) }), _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: title() })] }) }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Left, type: canUseBig()
                    ? Components.WidgetSideType.Big
                    : Components.WidgetSideType.None, isDecorated: responsive.xl, children: _jsx(Components.NavSideBar, {}) }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Bottom, type: Components.WidgetSideType.Big, children: _jsxs(Components.Layout, { type: Components.LayoutType.HorizontalStack, children: [_jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Main, children: _jsx("small", { children: _jsx(Components.VersionInfo, {}) }) }), _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: _jsxs(Components.BtnGroup, { children: [_jsx(Common.Placeholder, { name: "ContextButtons" }), (_a = props.widgets) === null || _a === void 0 ? void 0 : _a.bottomButtons, _jsx(Components.Button, { onClick: showAccount, children: _jsx(Components.IconPerson, {}) }), _jsx(Components.Button, { onClick: showSettings, children: _jsx(Components.IconSettings, {}) }), _jsx(Components.Button, { onClick: toggleLanguage, children: language.language }), _jsx(Components.Button, { onClick: showAbout, children: _jsx(Components.IconInfo, {}) })] }) })] }) }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Content, children: _jsx(React.Suspense, { children: props.children }) })] }));
}
