import React from 'react';
export function componentTag(name) {
    if (name) {
        return 'ui-' + name;
    }
    return 'div';
}
export var Importance;
(function (Importance) {
    Importance[Importance["Primary"] = 0] = "Primary";
    Importance[Importance["Normal"] = 1] = "Normal";
    Importance[Importance["Danger"] = 2] = "Danger";
    Importance[Importance["None"] = 3] = "None";
    Importance[Importance["Disabled"] = 4] = "Disabled";
})(Importance || (Importance = {}));
export function WithCommonProps(props, classes) {
    classes['primary'] = props.importance === Importance.Primary;
    classes['normal'] = props.importance === Importance.Normal;
    classes['danger'] = props.importance === Importance.Danger;
    classes['disabled'] = props.importance === Importance.Disabled;
    return classes;
}
export function Component(props) {
    var elementName = componentTag(props.name);
    return React.createElement(elementName, mapReactProps(props), props.children);
}
function mapReactProps(props) {
    var elementAttrs = {};
    for (var key in props) {
        if (key === 'name') {
            continue;
        }
        if (key === 'className') {
            elementAttrs['class'] = props[key];
        }
        else {
            elementAttrs[key] = props[key];
        }
    }
    return elementAttrs;
}
