import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import * as Components from '_components';
import * as Utils from '_utils';
import { Tiles } from './Tiles';
export function DashboardRender(component, index) {
    return (_jsx(Dashboard, { name: component.properties.name, children: _jsx(Components.SimpleComponentContent, { component: component }) }));
}
var DashboardContext = React.createContext({
    mode: null,
    setMode: function () { },
});
DashboardContext.displayName = 'DashboardContext';
export function useDashboardContext() {
    return React.useContext(DashboardContext);
}
export function Dashboard(props) {
    var _a = Utils.useParam('mode'), mode = _a[0], setMode = _a[1];
    var components = useDashboardComponents(props.name);
    var userInfo = Common.useUser();
    components = components.filter(function (c) { var _a; return Common.userHaveRole(userInfo, (_a = c.role) !== null && _a !== void 0 ? _a : 'missing'); });
    function getWidget(id, name, component, size, skipWidtget) {
        var controls = (_jsx(Components.BtnGroup, { small: true, children: _jsx(Components.Button, { onClick: function () { return setMode(id); }, children: "O" }) }));
        if (!component) {
            return (_jsx(Components.Button, { tileSize: size || Components.TileSize.ExtraBig, onClick: function () { return setMode(id); }, children: name }, id));
        }
        if (skipWidtget) {
            return _jsx(Components.Content, { component: component }, id);
        }
        return (_jsx(Components.MinimalWidget, { title: { en: name, cs: name }, tileSize: size || Components.TileSize.ExtraBig, controls: controls, children: _jsx(Components.Content, { component: component }) }, id));
    }
    function createTiles(section) {
        var compList = section.components.filter(function (c) { var _a; return Common.userHaveRole(userInfo, (_a = c.role) !== null && _a !== void 0 ? _a : 'missing'); });
        return (_jsx(Tiles, { children: compList.map(function (c) {
                return getWidget(c.id, c.name, c.small, c.size, c.skipWidtget);
            }) }, section.name));
    }
    function findComponent(id) {
        return components.flatMap(function (c) { return c.components; }).find(function (c) { return c.id === id; });
    }
    if (mode) {
        var component = findComponent(mode);
        if (component) {
            var controls = (_jsx(Components.BtnGroup, { small: true, children: _jsx(Components.Button, { onClick: function () { return setMode(null); }, children: "X" }) }));
            return (_jsx(Components.MinimalWidget, { title: { en: component.name, cs: component.name }, tileSize: Components.TileSize.ExtraBig, controls: controls, children: _jsx(Components.Content, { component: component.full }) }));
        }
    }
    return (_jsx(DashboardContext.Provider, { value: { mode: mode, setMode: setMode }, children: components.map(function (section) { return createTiles(section); }) }));
}
function useDashboardComponents(name) {
    return [
        {
            name: 'Default',
            role: 'User',
            components: [
                {
                    id: 'time',
                    name: 'Time',
                    role: 'User',
                    small: { type: 'Widgets_TimeSmall' },
                    full: { type: 'Widgets_TimeFull' },
                },
                {
                    id: 'weather',
                    name: 'Weather',
                    role: 'User',
                    small: { type: 'Widgets_WeatherSmall' },
                    full: { type: 'Widgets_WeatherFull' },
                },
                {
                    id: 'monitoring',
                    name: 'Monitoring',
                    role: 'Admin',
                    small: { type: 'Widgets_MonitoringSmall' },
                    full: { type: 'Widgets_MonitoringFull' },
                },
            ],
        },
        {
            name: 'House',
            role: 'House',
            components: [
                {
                    id: 'livingRoom',
                    name: 'Living Room',
                    role: 'House',
                    skipWidtget: true,
                    small: {
                        type: 'House_DashboardRoomButton',
                        properties: { name: 'livingRoom' },
                    },
                    full: {
                        type: 'House_dashboardroomfull',
                        properties: { name: 'livingRoom' },
                    },
                },
                {
                    id: 'office',
                    name: 'Office',
                    role: 'House',
                    skipWidtget: true,
                    small: {
                        type: 'House_DashboardRoomButton',
                        properties: { name: 'office' },
                    },
                    full: {
                        type: 'House_dashboardroomfull',
                        properties: { name: 'office' },
                    },
                },
                {
                    id: 'bedRoom',
                    name: 'Bad Room',
                    role: 'House',
                    skipWidtget: true,
                    small: {
                        type: 'House_DashboardRoomButton',
                        properties: { name: 'bedRoom' },
                    },
                    full: {
                        type: 'House_dashboardroomfull',
                        properties: { name: 'bedRoom' },
                    },
                },
                {
                    id: 'southRoom',
                    name: 'South Room',
                    role: 'House',
                    skipWidtget: true,
                    small: {
                        type: 'House_DashboardRoomButton',
                        properties: { name: 'southRoom' },
                    },
                    full: {
                        type: 'House_dashboardroomfull',
                        properties: { name: 'southRoom' },
                    },
                },
                {
                    id: 'northRoom',
                    name: 'North Room',
                    role: 'House',
                    skipWidtget: true,
                    small: {
                        type: 'House_DashboardRoomButton',
                        properties: { name: 'northRoom' },
                    },
                    full: {
                        type: 'House_dashboardroomfull',
                        properties: { name: 'northRoom' },
                    },
                },
                {
                    id: 'vestibule',
                    name: 'Vestibule',
                    role: 'House',
                    skipWidtget: true,
                    small: {
                        type: 'House_DashboardRoomButton',
                        properties: { name: 'vestibule' },
                    },
                    full: {
                        type: 'House_dashboardroomfull',
                        properties: { name: 'vestibule' },
                    },
                },
                {
                    id: 'upperHallway',
                    name: 'Upper Hallway',
                    role: 'House',
                    skipWidtget: true,
                    small: {
                        type: 'House_DashboardRoomButton',
                        properties: { name: 'upperHallway' },
                    },
                    full: {
                        type: 'House_dashboardroomfull',
                        properties: { name: 'upperHallway' },
                    },
                },
                {
                    id: 'laundry',
                    name: 'Laundry',
                    role: 'House',
                    skipWidtget: true,
                    small: {
                        type: 'House_DashboardRoomButton',
                        properties: { name: 'laundry' },
                    },
                    full: {
                        type: 'House_dashboardroomfull',
                        properties: { name: 'laundry' },
                    },
                },
            ],
        },
        {
            name: 'House2',
            role: 'House',
            components: [
                {
                    id: 'hwp',
                    name: 'Hot water pump',
                    role: 'House',
                    skipWidtget: true,
                    small: { type: 'House_HotWaterPumpButton' },
                    full: { type: 'House_HotWaterPump' },
                },
            ],
        },
        {
            name: 'Admin',
            role: 'Admin',
            components: [
                {
                    id: 'spa',
                    name: 'Context',
                    role: 'Admin',
                    size: Components.TileSize.SmallLong,
                    small: undefined,
                    full: { type: 'Widgets_SpaContextFull' },
                },
            ],
        },
    ];
}
