export function since(timestamp) {
    var now = new Date();
    var diff = now.getTime() - timestamp.getTime();
    var seconds = Math.floor(diff / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    if (seconds < 60)
        return {
            en: "".concat(seconds, " seconds ago"),
            cs: "p\u0159ed ".concat(seconds, " sekundami"),
        };
    if (minutes < 60)
        return { en: "".concat(minutes, " minutes ago"), cs: "p\u0159ed ".concat(minutes, " minutami") };
    if (hours < 24)
        return { en: "".concat(hours, " hours ago"), cs: "p\u0159ed ".concat(hours, " hodinami") };
    if (days < 7)
        return { en: "".concat(days, " days ago"), cs: "p\u0159ed ".concat(days, " dny") };
    return 'since ' + timestamp;
}
