import { jsx as _jsx } from "react/jsx-runtime";
import * as Common from '_common';
import * as Styles from './Tiles.module.scss';
export var TileSize;
(function (TileSize) {
    TileSize[TileSize["Small"] = 0] = "Small";
    TileSize[TileSize["Normal"] = 1] = "Normal";
    TileSize[TileSize["Big"] = 2] = "Big";
    TileSize[TileSize["ExtraBig"] = 3] = "ExtraBig";
    TileSize[TileSize["Long"] = 4] = "Long";
    TileSize[TileSize["ExtraLong"] = 5] = "ExtraLong";
    TileSize[TileSize["SmallLong"] = 6] = "SmallLong";
    TileSize[TileSize["LongThin"] = 7] = "LongThin";
    TileSize[TileSize["FullRow"] = 8] = "FullRow";
})(TileSize || (TileSize = {}));
export function WithTileProps(props, classes) {
    classes[Styles.tile_small] = props.tileSize === TileSize.Small;
    classes[Styles.tile_normal] = props.tileSize === TileSize.Normal;
    classes[Styles.tile_big] = props.tileSize === TileSize.Big;
    classes[Styles.tile_extra_big] = props.tileSize === TileSize.ExtraBig;
    classes[Styles.tile_long] = props.tileSize === TileSize.Long;
    classes[Styles.tile_extra_long] = props.tileSize === TileSize.ExtraLong;
    classes[Styles.tile_small_long] = props.tileSize === TileSize.SmallLong;
    classes[Styles.tile_long_thin] = props.tileSize === TileSize.LongThin;
    return classes;
}
export function Tiles(props) {
    return (_jsx(Common.Component, { name: 'tiles', className: Styles.tiles, children: props.children }));
}
