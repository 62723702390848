export function getLocalData(key) {
    if (!localStorage) {
        return;
    }
    try {
        var data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
    }
    catch (_a) {
        console.warn("Local storage item ".concat(key, " contains invalid json."));
    }
    return undefined;
}
export function setLocalData(key, data) {
    if (!localStorage) {
        return;
    }
    localStorage.setItem(key, JSON.stringify(data));
}
