var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as Utils from '_utils';
import * as React from 'react';
var themeStorageKey = 'theme';
export var ThemeDefault = 'standard';
export var ThemeLcars = 'lcars';
export var ThemeDark = 'dark';
export var ThemeLight = 'light';
export var ThemeContext = React.createContext(__assign(__assign({}, getDefaultTheme()), { setTheme: function () { } }));
ThemeContext.displayName = 'ThemeContext';
export function ThemeProvider(props) {
    var _a = React.useState(getDefaultTheme()), state = _a[0], setState = _a[1];
    function callback(theme) {
        setState(theme);
        var body = document.body;
        body.setAttribute('data-theme', theme.name);
        body.setAttribute('data-theme-variant', theme.variant);
        Utils.setLocalData(themeStorageKey, theme);
    }
    React.useEffect(function () {
        callback(getTheme());
    }, []);
    return (_jsx(ThemeContext.Provider, { value: __assign(__assign({}, state), { setTheme: callback }), children: props.children }));
}
export function useTheme() {
    return React.useContext(ThemeContext);
}
export function getTheme() {
    // TODO stroe the theme into the local storage
    return {
        name: ThemeLcars,
        variant: ThemeDark,
    };
}
export function getDefaultTheme() {
    return {
        name: ThemeLcars,
        variant: ThemeDark,
    };
}
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */ // will be used in the future
function getPreferredColorScheme() {
    if (window.matchMedia) {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            return 'dark';
        }
        else {
            return 'light';
        }
    }
    return 'light';
}
