import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as Common from '_common';
import * as Components from '_components';
import * as Utils from '_utils';
import * as Styles from './SimpleComponents.module.scss';
// export function Cmd(component: Common.IComponent, index?: number): React.ReactNode { }
export function Article(component, index) {
    return (_jsxs("article", { className: component.properties.type, children: [component.properties.hideHeader || _jsx(ArticleHeader, {}), _jsx(Components.SimpleComponentContent, { component: component })] }, index));
}
export function IfRender(component, index) {
    return (_jsx(If, { role: component.properties.role, culture: component.properties.culture, children: Components.RenderChildren(component.children) }, index));
}
export function If(props) {
    var language = Common.useLanguage();
    var user = Common.useUser();
    if (props.culture && props.culture !== language) {
        return [];
    }
    if (props.role && !Common.userHaveRole(user, props.role)) {
        return [];
    }
    return props.children;
}
export function TitleRender(component, index) {
    // TODO count nested sections
    return (_jsx("h1", { children: _jsx(Components.SimpleComponentContent, { component: component }) }, index));
}
export function ArticleContent(component, index) {
    // TODO styles
    return (_jsx("div", { className: "content", children: _jsx(Components.SimpleComponentContent, { component: component }) }, index));
}
export function ArticleHeader() {
    return (_jsx(_Fragment, { children: _jsx("hr", {}) }));
}
export function Section(component, index) {
    return (_jsx("section", { children: _jsx(Components.SimpleComponentContent, { component: component }) }, index));
}
export function List(component, index) {
    return (_jsx("ul", { children: _jsx(Components.SimpleComponentContent, { component: component }) }, index));
}
export function Item(component, index) {
    return (_jsx("li", { children: _jsx(Components.SimpleComponentContent, { component: component }) }, index));
}
export function Details(component, index) {
    // TODO styles
    return (_jsx("div", { className: "details", children: _jsx(Components.SimpleComponentContent, { component: component }) }, index));
}
export function VersionInfoRender(component, index) {
    return _jsx(VersionInfo, {}, index);
}
export function VersionInfo() {
    return _jsx("span", { children: document.appVersion });
}
export function MLTRender(component, index) {
    var _a, _b;
    return (_jsx(Components.MLT, { cs: (_a = component.properties) === null || _a === void 0 ? void 0 : _a.cs, en: (_b = component.properties) === null || _b === void 0 ? void 0 : _b.en }));
}
export function PropertyRender(component, index) {
    return (_jsx(Property, { title: MLTRender(component), children: _jsx(Components.SimpleComponentContent, { component: component }) }));
}
export function Property(props) {
    return (_jsx("div", { className: Styles.container, children: _jsxs("div", { className: Styles.property, children: [_jsx("label", { children: props.title }), _jsx("span", { className: Styles.delimiter, children: ":" }), _jsx("span", { className: Styles.value, children: props.children })] }) }));
}
export function Hr(component, index) {
    return _jsx("hr", {});
}
export function CodeRender(component, index) {
    var _a;
    return (_jsx("pre", { children: _jsx("code", { children: (_a = component.properties) === null || _a === void 0 ? void 0 : _a.text }) }));
}
export function Container(props) {
    var classNames = {};
    classNames = Common.WithCommonProps(props, classNames);
    classNames = Components.WithTileProps(props, classNames);
    var classes = Utils.classNames(classNames);
    return (_jsx(Common.Component, { name: "container", className: classes, children: props.children }));
}
