import { TimeSmallRender, TimeFullRender } from './Time';
import { WeatherSmallRender, WeatherFullRender } from './Weather';
import { RenderSpaContextVisualisationFull, RenderSpaContextVisualisationSmall, } from './SpaContextVisualisation';
import { MonitoringFullRender, MonitoringSmallRender } from './Monitoring';
export function widgetsComponentRegister(type) {
    switch (type) {
        case 'timesmall':
            return TimeSmallRender;
        case 'timefull':
            return TimeFullRender;
        case 'weathersmall':
            return WeatherSmallRender;
        case 'weatherfull':
            return WeatherFullRender;
        case 'monitoringsmall':
            return MonitoringSmallRender;
        case 'monitoringfull':
            return MonitoringFullRender;
        case 'spacontextsmall':
            return RenderSpaContextVisualisationSmall;
        case 'spacontextfull':
            return RenderSpaContextVisualisationFull;
    }
}
