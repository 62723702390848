import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import * as Components from '_components';
export function MonitoringSmallRender(component, index) {
    return _jsx(Monitoring, { full: false });
}
export function MonitoringFullRender(component, index) {
    return _jsx(Monitoring, { full: true });
}
export function Monitoring(props) {
    var _a;
    var context = Common.useSpaContext();
    function formatSize(size) {
        var resultSize = size / 1024;
        var unit = 'kB';
        if (resultSize > 1024) {
            resultSize = resultSize / 1024;
            unit = 'MB';
        }
        if (resultSize > 1024) {
            resultSize = resultSize / 1024;
            unit = 'GB';
        }
        return "".concat(Math.round(resultSize * 100) / 100, " ").concat(unit);
    }
    return Object.keys((_a = context === null || context === void 0 ? void 0 : context.monitoring) !== null && _a !== void 0 ? _a : {}).map(function (key) {
        var _a, _b, _c, _d, _e, _f;
        var service = context.monitoring[key];
        if (props.full) {
            var details_1 = JSON.parse(service.statusDetails);
            return (_jsxs("div", { children: [_jsx("h1", { children: key }), _jsx(Components.Property, { title: "Status", children: getServiceStatus(service) }), _jsx(Components.Property, { title: "Up time", children: (_a = service === null || service === void 0 ? void 0 : service.status) === null || _a === void 0 ? void 0 : _a.upTime }), _jsx(Components.Property, { title: "PID", children: (_b = service === null || service === void 0 ? void 0 : service.status) === null || _b === void 0 ? void 0 : _b.pid }), _jsx(Components.Property, { title: "Version", children: (_c = service === null || service === void 0 ? void 0 : service.status) === null || _c === void 0 ? void 0 : _c.version }), _jsx("h2", { children: "Services" }), _jsxs("table", { className: "visible_table", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Service" }), _jsx("th", { children: "Last success loop" }), _jsx("th", { children: "Last failed loop" }), _jsx("th", { children: "Fail loop count" })] }) }), _jsx("tbody", { children: Object.keys((_d = details_1.Services) !== null && _d !== void 0 ? _d : {}).map(function (key) {
                                    var _a;
                                    var service = details_1.Services[key];
                                    return (_jsxs(React.Fragment, { children: [_jsxs("tr", { children: [_jsx("td", { children: key }), _jsx("td", { children: service.LastSuccessLoop }), _jsx("td", { children: (_a = service.LastFailedLoop) !== null && _a !== void 0 ? _a : '-' }), _jsx("td", { children: service.FailLoopCount })] }, key), service.LastErrorMessage && (_jsx("tr", { children: _jsx("td", { colSpan: 4, children: _jsx("pre", { children: service.LastErrorMessage }) }) }))] }, key));
                                }) })] }), _jsx("h2", { children: "Queues" }), _jsxs("table", { className: "visible_table", children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Queue" }), _jsx("th", { children: "Item Count" })] }) }), _jsx("tbody", { children: ((_e = details_1.Queues) !== null && _e !== void 0 ? _e : []).map(function (queue) { return (_jsxs("tr", { children: [_jsx("td", { children: queue.Name }), _jsx("td", { children: queue.Count })] }, queue.Name)); }) })] }), _jsx("h2", { children: "File system" }), _jsx("table", { className: "visible_table", children: _jsx("tbody", { children: ((_f = details_1.Drives) !== null && _f !== void 0 ? _f : []).map(function (drive) { return (_jsxs("tr", { children: [_jsx("td", { children: drive.Name }), !drive.Size || drive.Size === 0 ? (_jsxs("td", { children: ["n/a", _jsx(Components.Property, { title: "Type", children: drive.DriveType })] })) : (_jsxs("td", { style: { minWidth: '150px' }, children: [_jsx(Components.Progress, { type: Components.ProgressType
                                                    .Value, max: drive.Size, value: drive.Size - drive.FreeSpace }), _jsx(Components.Property, { title: "Size", children: formatSize(drive.Size) }), _jsx(Components.Property, { title: "Free", children: formatSize(drive.FreeSpace) }), _jsx(Components.Property, { title: "Type", children: drive.DriveType })] }))] }, drive.Name)); }) }) })] }, key));
        }
        else {
            return (_jsx(Components.Property, { title: key, children: getServiceStatus(service) }, key));
        }
    });
}
function getServiceStatus(service) {
    if (!(service === null || service === void 0 ? void 0 : service.status)) {
        return 'unknown';
    }
    var treashold = Date.now() - 1000 * 20;
    if (Date.parse(service.status.timestamp) < treashold) {
        return 'ok';
    }
    else {
        return 'error';
    }
}
