var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { useEffect } from 'react';
import { HubConnectionBuilder, } from '@microsoft/signalr';
import { Ajax } from '_utils';
import * as Common from '_common';
export var SpaContext = React.createContext({});
SpaContext.displayName = 'SpaContext';
export function SpaContextProvider(props) {
    var connection = null;
    var interval;
    var _a = React.useState({}), state = _a[0], setState = _a[1];
    useEffect(function () {
        refreshCompleteState();
        startConnection();
        return function () {
            endConnection();
        };
    }, []);
    function startConnection() {
        if (!connection) {
            connection = new HubConnectionBuilder()
                .withUrl(Common.debugSettings.apiRoot + '/api/messages')
                .withAutomaticReconnect()
                .build();
            connection.on('StateChanged', function (partialValue) {
                try {
                    setState(function (prevState) {
                        return setContextProperty(prevState, partialValue);
                    });
                }
                catch (e) {
                    console.error(e);
                }
            });
            connection.start().catch(function (err) { return console.error(err); });
        }
        if (!interval) {
            interval = setInterval(refreshCompleteState, 60 * 1000);
            refreshCompleteState();
        }
    }
    function endConnection() {
        if (connection) {
            connection.stop();
            connection = null;
        }
        if (interval) {
            clearInterval(interval);
            interval = null;
        }
    }
    function refreshCompleteState() {
        return __awaiter(this, void 0, void 0, function () {
            var spaContext, mappedContext, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Ajax.get('/api/spa')];
                    case 1:
                        spaContext = _a.sent();
                        mappedContext = mapProperties(spaContext);
                        setState(mappedContext);
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        console.error(ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    return (_jsx(SpaContext.Provider, { value: state, children: props.children }));
}
export function useSpaContext() {
    var state = React.useContext(SpaContext);
    return state;
}
export function setContextProperty(current, newValues) {
    try {
        var result = __assign({}, current);
        // recursively merge the new values into the current context
        for (var key in newValues) {
            var normKey = normalizeKey(key);
            if (typeof newValues[key] === 'object') {
                if (!current[normKey]) {
                    current[normKey] = {};
                }
                result[normKey] = setContextProperty(current[normKey], newValues[key]);
            }
            else {
                if (current[normKey] !== newValues[key]) {
                    result[normKey] = newValues[key];
                }
            }
        }
        return result;
    }
    catch (e) {
        console.error(e);
        throw e;
    }
}
function normalizeKey(key) {
    if (!key || key.length === 0) {
        return '_';
    }
    if (key.length <= 3) {
        return key.toLowerCase();
    }
    // make first letter lowercase
    return key[0].toLowerCase() + key.substring(1);
}
function adjustPath(path) {
    if (!path || path.length === 0) {
        return '';
    }
    var adjustedPath = path[0].toLowerCase() + path.substring(1);
    if (adjustedPath.length <= 3) {
        adjustedPath = adjustedPath.toLowerCase();
    }
    return adjustedPath;
}
function mapProperties(object) {
    var result = {};
    for (var key in object) {
        var item = object[key];
        if (typeof item === 'object') {
            item = mapProperties(item);
        }
        result[adjustPath(key)] = item;
    }
    return result;
}
