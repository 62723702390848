import { RenderDashboardRoomButton, RenderDashboardRoomFull, RenderHotWaterPumpButtonRender, } from './Dashboard';
import { GraphsRender } from './Graphs';
export function houseComponentRegister(type) {
    switch (type) {
        case 'graphs':
            return GraphsRender;
        case 'dashboardroombutton':
            return RenderDashboardRoomButton;
        case 'dashboardroomfull':
            return RenderDashboardRoomFull;
        case 'hotwaterpumpbutton':
            return RenderHotWaterPumpButtonRender;
    }
}
// export function Cmp(component: Common.IComponent, index?: number): React.ReactNode {
// }
