import * as React from 'react';
import { getAppModule } from '_appModuleRegister';
export var SelectedAppModuleContext = React.createContext(null);
SelectedAppModuleContext.displayName = 'SelectedAppModuleContext';
export function useCurrentModule() {
    var context = React.useContext(SelectedAppModuleContext);
    if (!context) {
        return undefined;
    }
    return getAppModule(context);
}
