var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DebugPage } from './../../app/DebugPage';
import React from 'react';
import * as Common from '_common';
import * as Components from '_components';
import * as Utils from '_utils';
import { BtnGroup, useToggle } from './Button';
import * as Styles from './Widget.module.scss';
export var WidgetType;
(function (WidgetType) {
    WidgetType[WidgetType["Minimal"] = 0] = "Minimal";
    WidgetType[WidgetType["MinimalWithControls"] = 1] = "MinimalWithControls";
    WidgetType[WidgetType["MinimalDecorated"] = 2] = "MinimalDecorated";
    WidgetType[WidgetType["Normal"] = 3] = "Normal";
})(WidgetType || (WidgetType = {}));
export function Widget(props) {
    var theme = Common.useTheme();
    var classNames = {
    // [Styles.theme_lcars]: theme.name === Common.ThemeLcars,
    // [Styles.theme_standard]: theme.name === Common.ThemeDefault,
    };
    classNames = Common.WithCommonProps(props, classNames);
    classNames = Components.WithTileProps(props, classNames);
    var classes = Utils.classNames(classNames);
    return (_jsx(Common.Component, { name: "widget", className: classes, children: props.children }));
}
export var WidgetSideType;
(function (WidgetSideType) {
    WidgetSideType[WidgetSideType["None"] = 0] = "None";
    WidgetSideType[WidgetSideType["Small"] = 1] = "Small";
    WidgetSideType[WidgetSideType["Big"] = 2] = "Big";
})(WidgetSideType || (WidgetSideType = {}));
export var WidgetComponentType;
(function (WidgetComponentType) {
    WidgetComponentType[WidgetComponentType["Left"] = 0] = "Left";
    WidgetComponentType[WidgetComponentType["Right"] = 1] = "Right";
    WidgetComponentType[WidgetComponentType["Top"] = 2] = "Top";
    WidgetComponentType[WidgetComponentType["Bottom"] = 3] = "Bottom";
    WidgetComponentType[WidgetComponentType["Content"] = 4] = "Content";
})(WidgetComponentType || (WidgetComponentType = {}));
export function WidgetComponent(props) {
    var _a;
    if (props.type === WidgetSideType.None) {
        return _jsx(_Fragment, {});
    }
    var classes = Utils.classNames(Common.WithCommonProps(props, (_a = {},
        _a['context-panel'] = props.side !== WidgetComponentType.Content,
        _a[Styles.variant_small] = props.type === WidgetSideType.Small,
        _a[Styles.variant_normal] = props.type === WidgetSideType.Big,
        _a[Styles.side_left] = props.side === WidgetComponentType.Left,
        _a[Styles.side_right] = props.side === WidgetComponentType.Right,
        _a[Styles.side_top] = props.side === WidgetComponentType.Top,
        _a[Styles.side_bottom] = props.side === WidgetComponentType.Bottom,
        _a.is_decorated = props.isDecorated || false,
        _a[Styles.content] = props.side === WidgetComponentType.Content,
        _a.horizontal = props.side === WidgetComponentType.Top ||
            props.side === WidgetComponentType.Bottom,
        _a.vertical = props.side === WidgetComponentType.Left ||
            props.side === WidgetComponentType.Right,
        _a)));
    var content = props.children;
    if (props.side === WidgetComponentType.Content) {
        content = _jsx("div", { className: Styles.inner, children: content });
    }
    var decoration = props.isDecorated ? (_jsx("div", { className: Styles.decoration })) : (_jsx(_Fragment, {}));
    if (props.side === WidgetComponentType.Content) {
        return (_jsxs(Common.Component, { name: "widget-content", className: classes, children: [decoration, content] }));
    }
    else {
        return (_jsxs(Common.Component, { name: "widget-side", className: classes, children: [decoration, content] }));
    }
}
export function MinimalWidget(props) {
    return (_jsxs(Widget, __assign({}, props, { children: [_jsx(WidgetComponent, { type: WidgetSideType.Small, side: WidgetComponentType.Top, children: _jsxs(Components.Layout, { type: Components.LayoutType.HorizontalStack, children: [_jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Main, children: props.title && (_jsx("h2", { children: _jsx(Components.MLT, { text: props.title }) })) }), _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: props.controls })] }) }), _jsx(WidgetComponent, { type: WidgetSideType.Small, side: WidgetComponentType.Left }), _jsx(WidgetComponent, { type: WidgetSideType.Small, side: WidgetComponentType.Right }), _jsx(WidgetComponent, { type: WidgetSideType.Small, side: WidgetComponentType.Bottom }), _jsx(WidgetComponent, { side: WidgetComponentType.Content, children: props.children })] })));
}
export function DialogWidget(props) {
    return (_jsxs(Widget, __assign({}, props, { children: [_jsx(WidgetComponent, { type: WidgetSideType.Big, side: WidgetComponentType.Top, children: _jsx(Components.Layout, { type: Components.LayoutType.HorizontalStack, children: _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: props.title && (_jsx("h1", { children: _jsx(Components.MLT, { text: props.title }) })) }) }) }), _jsx(WidgetComponent, { type: WidgetSideType.Big, side: WidgetComponentType.Bottom, children: _jsx(Components.Layout, { type: Components.LayoutType.HorizontalStack, children: _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: props.controls }) }) }), _jsx(WidgetComponent, { side: WidgetComponentType.Content, children: props.children })] })));
}
export function WidgetRender(component, index) {
    return (_jsx(MinimalWidget, { children: _jsx(Components.SimpleComponentContent, { component: component }) }));
}
// ---------------------------------------------------
// DEBUG PAGE
export function WidgetDebug(component, index) {
    var _a = useWidgetSideType(), topType = _a[0], topControls = _a[1], setTop = _a[2];
    var _b = useWidgetSideType(), leftType = _b[0], leftControls = _b[1], setLeft = _b[2];
    var _c = useWidgetSideType(), rightType = _c[0], rightControls = _c[1], setRight = _c[2];
    var _d = useWidgetSideType(), bottomType = _d[0], bottomControls = _d[1], setBottom = _d[2];
    var _e = useDebugSideContent(true), topCntControls = _e[0], topContent = _e[1], topSetCnt = _e[2];
    var _f = useDebugSideContent(false), leftCntControls = _f[0], leftContent = _f[1], leftSetCnt = _f[2];
    var _g = useDebugSideContent(false), rightCntControls = _g[0], rightContent = _g[1], rightSetCnt = _g[2];
    var _h = useDebugSideContent(true), bottomCntControls = _h[0], bottomContent = _h[1], bottomSetCnt = _h[2];
    var _j = useToggle({
        children: 'Is decorated',
    }), topDecorated = _j[0], topDecControl = _j[1];
    var _k = useToggle({
        children: 'Is decorated',
    }), leftDecorated = _k[0], leftDecControl = _k[1];
    var _l = useToggle({
        children: 'Is decorated',
    }), rightDecorated = _l[0], rightDecControl = _l[1];
    var _m = useToggle({
        children: 'Is decorated',
    }), bottomDecorated = _m[0], bottomDecControl = _m[1];
    function preset(top, left, right, bottom) {
        setTop(top);
        setLeft(left);
        setRight(right);
        setBottom(bottom);
        if (top === WidgetSideType.None || top === WidgetSideType.Small) {
            topSetCnt('None');
        }
        else {
            topSetCnt('Buttons with title');
        }
        if (left === WidgetSideType.None || left === WidgetSideType.Small) {
            leftSetCnt('None');
        }
        else {
            leftSetCnt('Complex');
        }
        if (right === WidgetSideType.None || right === WidgetSideType.Small) {
            rightSetCnt('None');
        }
        else {
            rightSetCnt('More buttons');
        }
        if (bottom === WidgetSideType.None || bottom === WidgetSideType.Small) {
            bottomSetCnt('None');
        }
        else {
            bottomSetCnt('More buttons');
        }
    }
    var controls = (_jsxs(_Fragment, { children: [_jsx("h3", { children: "Presets" }), _jsx(Components.Button, { onClick: function () {
                    return preset(WidgetSideType.None, WidgetSideType.None, WidgetSideType.None, WidgetSideType.None);
                }, children: "None" }), _jsx(Components.Button, { onClick: function () {
                    return preset(WidgetSideType.Small, WidgetSideType.Small, WidgetSideType.Small, WidgetSideType.Small);
                }, children: "Small" }), _jsx(Components.Button, { onClick: function () {
                    return preset(WidgetSideType.Big, WidgetSideType.None, WidgetSideType.None, WidgetSideType.Big);
                }, children: "Minimal" }), _jsx(Components.Button, { onClick: function () {
                    return preset(WidgetSideType.None, WidgetSideType.Small, WidgetSideType.Small, WidgetSideType.None);
                }, children: "Dialog" }), _jsx(Components.Button, { onClick: function () {
                    return preset(WidgetSideType.Small, WidgetSideType.Big, WidgetSideType.None, WidgetSideType.Small);
                }, children: "Small Left U" }), _jsx(Components.Button, { onClick: function () {
                    return preset(WidgetSideType.Small, WidgetSideType.None, WidgetSideType.Big, WidgetSideType.Small);
                }, children: "Small Right U" }), _jsx(Components.Button, { onClick: function () {
                    return preset(WidgetSideType.Big, WidgetSideType.Big, WidgetSideType.None, WidgetSideType.Big);
                }, children: "Full Left U" }), _jsx(Components.Button, { onClick: function () {
                    return preset(WidgetSideType.Big, WidgetSideType.None, WidgetSideType.Big, WidgetSideType.Big);
                }, children: "Full Right U" }), _jsx("h3", { children: "Sides" }), _jsx("h4", { children: "Top" }), topControls, _jsx("hr", {}), topCntControls, _jsx("hr", {}), topDecControl, _jsx("h4", { children: "Left" }), leftControls, _jsx("hr", {}), leftCntControls, _jsx("hr", {}), leftDecControl, _jsx("h4", { children: "Right" }), rightControls, _jsx("hr", {}), rightCntControls, _jsx("hr", {}), rightDecControl, _jsx("h4", { children: "Bottom" }), bottomControls, _jsx("hr", {}), bottomCntControls, _jsx("hr", {}), bottomDecControl] }));
    return (_jsx(DebugPage, { controls: controls, children: _jsxs(Widget, { children: [_jsx(WidgetComponent, { type: topType, side: WidgetComponentType.Top, isDecorated: topDecorated, children: topContent() }), _jsx(WidgetComponent, { type: leftType, side: WidgetComponentType.Left, isDecorated: leftDecorated, children: leftContent() }), _jsx(WidgetComponent, { type: rightType, side: WidgetComponentType.Right, isDecorated: rightDecorated, children: rightContent() }), _jsx(WidgetComponent, { type: bottomType, side: WidgetComponentType.Bottom, isDecorated: bottomDecorated, children: bottomContent() }), _jsxs(WidgetComponent, { side: WidgetComponentType.Content, children: [_jsx("h1", { children: "Content" }), _jsx("p", { children: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Vivamus luctus egestas leo. Nulla est. Nullam feugiat, turpis at pulvinar vulputate, erat libero tristique tellus, nec bibendum odio risus sit amet ante. Nulla quis diam. Aliquam ornare wisi eu metus. Nunc dapibus tortor vel mi dapibus sollicitudin. Pellentesque ipsum. Praesent dapibus. Donec vitae arcu. Fusce tellus odio, dapibus id fermentum quis, suscipit id erat. Integer rutrum, orci vestibulum ullamcorper ultricies, lacus quam ultricies odio, vitae placerat pede sem sit amet enim. Duis ante orci, molestie vitae vehicula venenatis, tincidunt ac pede." })] })] }) }));
}
function useWidgetSideType() {
    var _a = React.useState(WidgetSideType.None), type = _a[0], setType = _a[1];
    var controls = (_jsxs(_Fragment, { children: [_jsx(Components.Button, { isActive: type === WidgetSideType.None, onClick: function () { return setType(WidgetSideType.None); }, children: "None" }), _jsx(Components.Button, { isActive: type === WidgetSideType.Small, onClick: function () { return setType(WidgetSideType.Small); }, children: "Small" }), _jsx(Components.Button, { isActive: type === WidgetSideType.Big, onClick: function () { return setType(WidgetSideType.Big); }, children: "Big" })] }));
    return [type, controls, setType];
}
function useDebugSideContent(isHoriyontal) {
    var _a = React.useState('None'), type = _a[0], setType = _a[1];
    function createButton(btnType) {
        return (_jsx(Components.Button, { isActive: type === btnType, onClick: function () { return setType(btnType); }, children: btnType }));
    }
    var controls = (_jsxs(_Fragment, { children: [createButton('None'), createButton('Buttons'), createButton('More buttons'), createButton('Buttons with title'), createButton('Complex')] }));
    function createContent() {
        if (type === 'None') {
            return _jsx(_Fragment, {});
        }
        if (type === 'Buttons') {
            return (_jsxs(BtnGroup, { children: [_jsx(Components.Button, { children: "Btn 1" }), _jsx(Components.Button, { children: "Btn 2" }), _jsx(Components.Button, { children: "Btn 3" })] }));
        }
        if (type === 'More buttons') {
            return (_jsxs(Components.Layout, { type: isHoriyontal
                    ? Components.LayoutType.HorizontalStack
                    : Components.LayoutType.VerticalStack, children: [_jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Main, children: _jsxs(BtnGroup, { children: [_jsx(Components.Button, { children: "Btn 1" }), _jsx(Components.Button, { children: "Btn 2" }), _jsx(Components.Button, { children: "Btn 3" }), _jsx(Components.Button, { children: "Btn 4" }), _jsx(Components.Button, { children: "Btn 5" }), _jsx(Components.Button, { children: "Btn 6" })] }) }), _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: _jsxs(BtnGroup, { children: [_jsx(Components.Button, { children: "Btn 7" }), _jsx(Components.Button, { children: "Btn 8" }), _jsx(Components.Button, { children: "Btn 9" })] }) })] }));
        }
        if (type === 'Buttons with title') {
            return (_jsxs(Components.Layout, { type: isHoriyontal
                    ? Components.LayoutType.HorizontalStack
                    : Components.LayoutType.VerticalStack, children: [_jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Main, children: _jsxs(BtnGroup, { children: [_jsx(Components.Button, { children: "Btn 1" }), _jsx(Components.Button, { children: "Btn 2" }), _jsx(Components.Button, { children: "Btn 3" })] }) }), _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: _jsx("h1", { children: "Title" }) })] }));
        }
        if (type === 'Complex') {
            return _jsx(Components.DebugNavSideBar, {});
        }
        return _jsx(_Fragment, {});
    }
    return [controls, createContent, setType];
}
