export function toBool(value) {
    if (!value) {
        return false;
    }
    if (typeof value === 'string') {
        var lower = value.toLowerCase();
        return (lower === 'true' ||
            lower === 'yes' ||
            lower === 'on' ||
            lower === '1');
    }
    if (typeof value === 'number') {
        return value === 1;
    }
    return false;
}
export function toDate(value) {
    return new Date(value);
}
