import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as Common from '_common';
import { AppScreen } from './app/AppScreen';
import { SettingsDialog } from './app/SettingDialog';
import { Overlay } from './app/Overlay';
function App() {
    return (_jsx(Common.AppStateManager, { children: _jsx(React.StrictMode, { children: _jsxs(BrowserRouter, { children: [_jsx(SettingsDialog, {}), _jsx(Overlay, {}), _jsx(AppScreen, {})] }) }) }));
}
export default App;
