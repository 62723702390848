// extracted by mini-css-extract-plugin
var _1 = "src-app-DebugPage-module__content";
var _2 = "src-app-DebugPage-module__context_toggles";
var _3 = "src-app-DebugPage-module__controls";
var _4 = "src-app-DebugPage-module__debug_page";
var _5 = "src-app-DebugPage-module__inner_content";
var _6 = "src-app-DebugPage-module__screen_root";
export { _1 as "content", _2 as "context_toggles", _3 as "controls", _4 as "debug_page", _5 as "inner_content", _6 as "screen_root" }

    if(module.hot) {
      (function() {
        var localsJsonString = "{\"content\":\"src-app-DebugPage-module__content\",\"context_toggles\":\"src-app-DebugPage-module__context_toggles\",\"controls\":\"src-app-DebugPage-module__controls\",\"debug_page\":\"src-app-DebugPage-module__debug_page\",\"inner_content\":\"src-app-DebugPage-module__inner_content\",\"screen_root\":\"src-app-DebugPage-module__screen_root\"}";
        // 1728929521446
        var cssReload = require("../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  