import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import * as Utils from '_utils';
import * as Styles from './Layout.module.scss';
import { DebugPage, useToggles } from './../../app/DebugPage';
import { SimpleComponentContent } from '_components';
function LayoutComponent(className, children) {
    return _jsx("div", { className: className, children: children });
}
export var DockPanel = function (props) {
    return LayoutComponent(Styles.dock_panel, props.children);
};
export var DockPanelTop = function (props) {
    return LayoutComponent(Styles.top, props.children);
};
export var DockPanelMain = function (props) {
    return LayoutComponent(Styles.main, props.children);
};
export var DockPanelBottom = function (props) {
    return LayoutComponent(Styles.bottom, props.children);
};
export var DockPanelLeft = function (props) {
    return LayoutComponent(Styles.left, props.children);
};
export var DockPanelRight = function (props) {
    return LayoutComponent(Styles.right, props.children);
};
export var LayoutType;
(function (LayoutType) {
    LayoutType[LayoutType["MainWithWidgets"] = 0] = "MainWithWidgets";
    LayoutType[LayoutType["MainWithTopWidgets"] = 1] = "MainWithTopWidgets";
    LayoutType[LayoutType["VerticalStack"] = 2] = "VerticalStack";
    LayoutType[LayoutType["HorizontalStack"] = 3] = "HorizontalStack";
})(LayoutType || (LayoutType = {}));
export var LayoutChildrenSize;
(function (LayoutChildrenSize) {
    LayoutChildrenSize[LayoutChildrenSize["Unspecified"] = 0] = "Unspecified";
    LayoutChildrenSize[LayoutChildrenSize["Same"] = 1] = "Same";
})(LayoutChildrenSize || (LayoutChildrenSize = {}));
export function Layout(props) {
    var _a;
    var classes = Utils.classNames((_a = {},
        //[Styles.with_widgets]: props.type === LayoutType.MainWithWidgets,
        _a[Styles.top_widgets] = props.type === LayoutType.MainWithTopWidgets,
        _a[Styles.vertical_stack] = props.type === LayoutType.VerticalStack,
        _a[Styles.horizontal_stack] = props.type === LayoutType.HorizontalStack,
        _a));
    var style = {};
    if (props.childSize === LayoutChildrenSize.Same) {
        //var number = React.Children.count(props.children);
        if (props.type === LayoutType.VerticalStack) {
            style['grid-template-rows'] = "1fr 5px 1fr";
        }
        else if (props.type === LayoutType.HorizontalStack) {
            style['grid-template-columns'] = "1fr 5px 1fr";
        }
    }
    return (_jsx(Common.Component, { name: "layout", className: classes, style: style, children: props.children }));
}
export function RowRender(component, index) {
    return (_jsx(Row, { children: _jsx(SimpleComponentContent, { component: component }) }));
}
export function Row(props) {
    var number = React.Children.count(props.children);
    var style = {
        display: 'grid',
        width: '100%',
        height: '100%',
        gap: '5px',
    };
    style['gridTemplateColumns'] = "repeat(".concat(number, ", 1fr)");
    return _jsx("div", { style: style, children: props.children });
}
export function Full(props) {
    var number = React.Children.count(props.children);
    var style = {
        display: 'grid',
        width: '100%',
        height: '100%',
        gap: '5px',
    };
    style['gridTemplateRows'] = "repeat(".concat(number - 1, ", max-content) 1fr");
    return _jsx("div", { style: style, children: props.children });
}
export var LayoutAreasNames = {
    MainWithWidgets: {
        Main: Styles.area_main,
        LeftWidgets: 'Styles.area_left - TODO',
        RightWidgets: 'Styles.area_right - TODO',
    },
    MainWithTopWidgets: {
        Top: Styles.area_top,
        Main: Styles.area_main,
    },
    Stack: {
        Main: Styles.area_main,
        Side: Styles.area_side,
    },
};
export function LayoutArea(props) {
    var _a;
    var classes = Utils.classNames((_a = {},
        // [Styles.area]: true,
        _a[props.name] = true,
        _a));
    return _jsx("div", { className: classes, children: props.children });
}
export var DynamicLayoutType;
(function (DynamicLayoutType) {
    DynamicLayoutType[DynamicLayoutType["Normal"] = 0] = "Normal";
    DynamicLayoutType[DynamicLayoutType["HideWidgets"] = 1] = "HideWidgets";
    DynamicLayoutType[DynamicLayoutType["Presentation"] = 2] = "Presentation";
    DynamicLayoutType[DynamicLayoutType["Minimal"] = 3] = "Minimal";
})(DynamicLayoutType || (DynamicLayoutType = {}));
export function LayoutDebug() {
    var _a = React.useState(LayoutType.HorizontalStack), type = _a[0], setType = _a[1];
    var _b = useToggles({
        top: true,
        side: true,
        main: true,
        left: true,
        right: true,
    }), areas = _b[0], setAreasButtons = _b[1];
    var controls = (_jsxs(_Fragment, { children: [_jsx("h3", { children: "Type:" }), _jsx("button", { className: type === LayoutType.HorizontalStack ? 'active' : '', onClick: function () { return setType(LayoutType.HorizontalStack); }, children: "HorizontalStack" }), _jsx("button", { className: type === LayoutType.VerticalStack ? 'active' : '', onClick: function () { return setType(LayoutType.VerticalStack); }, children: "VerticalStack" }), _jsx("button", { className: type === LayoutType.MainWithWidgets ? 'active' : '', onClick: function () { return setType(LayoutType.MainWithWidgets); }, children: "MainWithWidgets" }), _jsx("button", { className: type === LayoutType.MainWithTopWidgets ? 'active' : '', onClick: function () { return setType(LayoutType.MainWithTopWidgets); }, children: "MainWithTopWidgets" }), _jsx("h3", { children: "Areas" }), setAreasButtons] }));
    return (_jsx(DebugPage, { controls: controls, children: _jsxs(Layout, { type: type, children: [type === LayoutType.HorizontalStack && areas['side'] && (_jsx(LayoutArea, { name: LayoutAreasNames.Stack.Side, children: _jsx("div", { style: { border: '1px solid green' }, children: "Side" }) })), type === LayoutType.HorizontalStack && areas['main'] && (_jsx(LayoutArea, { name: LayoutAreasNames.Stack.Main, children: _jsx("div", { style: { border: '1px solid blue' }, children: "Main" }) })), type === LayoutType.VerticalStack && areas['side'] && (_jsx(LayoutArea, { name: LayoutAreasNames.Stack.Side, children: _jsx("div", { style: { border: '1px solid green' }, children: "Side" }) })), type === LayoutType.VerticalStack && areas['main'] && (_jsx(LayoutArea, { name: LayoutAreasNames.Stack.Main, children: _jsx("div", { style: { border: '1px solid blue' }, children: "Main" }) })), type === LayoutType.MainWithTopWidgets && areas['top'] && (_jsx(LayoutArea, { name: LayoutAreasNames.MainWithTopWidgets.Top, children: _jsx("div", { style: { border: '1px solid green' }, children: "Top" }) })), type === LayoutType.MainWithTopWidgets && areas['main'] && (_jsx(LayoutArea, { name: LayoutAreasNames.MainWithTopWidgets.Main, children: _jsx("div", { style: { border: '1px solid blue' }, children: "Main" }) })), type === LayoutType.MainWithWidgets && areas['left'] && (_jsx(LayoutArea, { name: LayoutAreasNames.MainWithWidgets.LeftWidgets, children: _jsx("div", { style: { border: '1px solid green' }, children: "Left" }) })), type === LayoutType.MainWithWidgets && areas['right'] && (_jsx(LayoutArea, { name: LayoutAreasNames.MainWithWidgets.RightWidgets, children: _jsx("div", { style: { border: '1px solid green' }, children: "Right" }) })), type === LayoutType.MainWithWidgets && areas['main'] && (_jsx(LayoutArea, { name: LayoutAreasNames.MainWithWidgets.Main, children: _jsx("div", { style: { border: '1px solid blue' }, children: "Main" }) }))] }) }));
}
