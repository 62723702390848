import { jsx as _jsx } from "react/jsx-runtime";
import * as Common from '_common';
import { PageLayoutController } from './PageLayoutController';
import { ErrorOverlay } from './Overlay';
export function LoadingPage(props) {
    var lines = [];
    if (typeof props.loadingState == 'string') {
        lines = [props.loadingState];
    }
    else {
        lines = props.loadingState;
    }
    return (_jsx(PageLayoutController, { preferMinimal: true, title: { cs: 'Michalf.cz', en: 'Michalf.cz' }, children: _jsx("ul", { children: lines.map(function (title, i) { return (_jsx("li", { children: title }, i)); }) }) }));
}
export function ErrorScreen(props) {
    return (_jsx(PageLayoutController, { preferMinimal: true, title: { cs: 'Michalf.cz', en: 'Michalf.cz' }, children: _jsx(ErrorOverlay, { type: Common.OverlayType.Error }) }));
}
