var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import * as Utils from '_utils';
import { DebugPage } from './../../app/DebugPage';
import { Button, Property, IconClose, IconDone, useToggle } from '_components';
import * as Styles from './Inputs.module.scss';
export function InputBase(props) {
    var _a;
    var lang = Common.useLanguage();
    var classes = Utils.classNames((_a = {},
        _a[Styles.is_valid] = props.isValid,
        _a[Styles.is_invalid] = props.isInvalid,
        _a));
    return (_jsx(Common.Component, { name: 'input', className: classes, children: _jsxs(Property, { title: Common.LocalText(props.label, lang), children: [_jsx("input", { type: props.type, className: "form-control", id: props.name, placeholder: Common.LocalText(props.placeholder, lang), value: props.value, onChange: props.onChange, onBlur: props.onBlur }), props.controls && (_jsx("div", { className: Styles.controls, children: props.controls })), props.validationMessage && (_jsx("div", { className: Styles.feedback, children: props.validationMessage }))] }) }));
}
export function TextInput(props) {
    return _jsx(InputBase, __assign({}, props, { type: "text" }));
}
export function PasswordInput(props) {
    return _jsx(InputBase, __assign({}, props, { type: "password" }));
}
export function EditableProperty(props) {
    var _a = React.useState(props.value), value = _a[0], setValue = _a[1];
    var _b = React.useState(false), inEditMode = _b[0], setEditMode = _b[1];
    var lang = Common.useLanguage();
    React.useEffect(function () {
        setValue(props.value);
    }, [props.value]);
    function reset() {
        setValue(props.value);
        setEditMode(false);
    }
    function submit() {
        if (props.onSubmit) {
            props.onSubmit(value);
        }
        setEditMode(false);
    }
    function goEditMode() {
        if (!props.disabled) {
            setEditMode(true);
        }
    }
    var controls = (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return submit(); }, children: _jsx(IconDone, {}) }), _jsx(Button, { onClick: function () { return reset(); }, children: _jsx(IconClose, {}) })] }));
    if (inEditMode) {
        return (_jsx(TextInput, __assign({}, props, { name: "prop", onChange: function (event) { return setValue(event.target.value); }, controls: controls, value: value !== null && value !== void 0 ? value : '' })));
    }
    else {
        if (props.hideTitle) {
            return (_jsx("a", { onClick: function () { return goEditMode(); }, href: "#", children: value !== null && value !== void 0 ? value : '--' }));
        }
        return (_jsx(Property, { title: Common.LocalText(props.label, lang), children: _jsx("a", { onClick: function () { return goEditMode(); }, href: "#", children: value !== null && value !== void 0 ? value : '--' }) }));
    }
}
export function EditorDebug(component, index) {
    var _a = useToggle({ children: 'Touched' }), touched = _a[0], touchedControl = _a[1];
    var _b = useToggle({ children: 'Is Invalid' }), invalid = _b[0], InvalidControl = _b[1];
    var _c = React.useState('text'), text = _c[0], setText = _c[1];
    var controls = (_jsxs(_Fragment, { children: [touchedControl, InvalidControl] }));
    return (_jsxs(DebugPage, { controls: controls, children: [_jsx(TextInput, { label: { cs: 'Text', en: 'Text 1' }, name: "text", value: text, onChange: function (event) { return setText(event.target.value); }, isValid: touched && !invalid, isInvalid: invalid, validationMessage: invalid ? 'Validation message' : undefined }), _jsx("hr", {}), _jsx(Property, { title: "Text value", children: text }), _jsx(EditableProperty, { label: { en: 'Editable property' }, value: text, onSubmit: function (value) {
                    setText(value);
                } })] }));
}
