import { jsx as _jsx } from "react/jsx-runtime";
export function minecraftComponentRegister(type) {
    switch (type) {
        case 'map':
            return MapRender;
    }
}
// export function Cmp(component: Common.IComponent, index?: number): React.ReactNode {
// }
export function MapRender(component, index) {
    return _jsx(MinecraftMap, {}, index);
}
export function MinecraftMap() {
    return (_jsx("iframe", { style: {
            width: '100%',
            height: '100%',
            inset: 0,
            position: 'absolute',
            border: 'none',
        }, title: "map", src: "/mcmap/index.html" }));
}
