import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as Common from '_common';
import * as Components from '_components';
export function WeatherSmallRender(component, index) {
    return _jsx(Weather, { full: false });
}
export function WeatherFullRender(component, index) {
    return _jsx(Weather, { full: true });
}
export function Weather(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var context = Common.useSpaContext();
    var details = [];
    details.push(_jsxs(Components.Property, { title: _jsx(Components.MLT, { cs: "Vlhkost", en: "Humidity" }), children: [(_b = (_a = context === null || context === void 0 ? void 0 : context.state) === null || _a === void 0 ? void 0 : _a.outside) === null || _b === void 0 ? void 0 : _b.humidity, "%"] }, "1"));
    details.push(_jsxs(Components.Property, { title: _jsx(Components.MLT, { cs: "Tlak", en: "Pressure" }), children: [(_d = (_c = context === null || context === void 0 ? void 0 : context.state) === null || _c === void 0 ? void 0 : _c.outside) === null || _d === void 0 ? void 0 : _d.pressure, "hPa"] }, "2"));
    details.push(_jsxs(Components.Property, { title: _jsx(Components.MLT, { cs: "Slunce", en: "Sun power" }), children: [(_f = (_e = context === null || context === void 0 ? void 0 : context.state) === null || _e === void 0 ? void 0 : _e.outside) === null || _f === void 0 ? void 0 : _f.sunPower, "W/m2"] }, "3"));
    if (props.full) {
        details.push(_jsxs(Components.Property, { title: _jsx(Components.MLT, { cs: "V\u00EDtr", en: "Wind" }), children: [(_h = (_g = context === null || context === void 0 ? void 0 : context.state) === null || _g === void 0 ? void 0 : _g.outside) === null || _h === void 0 ? void 0 : _h.windSpeed, "m/s"] }, "4"));
        details.push(_jsxs(Components.Property, { title: _jsx(Components.MLT, { cs: "Pocitov\u00E1 teplota", en: "Wind chill" }), children: [(_k = (_j = context === null || context === void 0 ? void 0 : context.state) === null || _j === void 0 ? void 0 : _j.outside) === null || _k === void 0 ? void 0 : _k.windChill, "\u00B0C"] }, "5"));
        details.push(_jsxs(Components.Property, { title: _jsx(Components.MLT, { cs: "Rosn\u00FD bod", en: "Dew point" }), children: [(_m = (_l = context === null || context === void 0 ? void 0 : context.state) === null || _l === void 0 ? void 0 : _l.outside) === null || _m === void 0 ? void 0 : _m.dewPoint, "\u00B0C"] }, "6"));
    }
    return (_jsxs(_Fragment, { children: [_jsxs("h1", { children: [(_p = (_o = context === null || context === void 0 ? void 0 : context.state) === null || _o === void 0 ? void 0 : _o.outside) === null || _p === void 0 ? void 0 : _p.temperature, "\u00B0C"] }), details] }));
}
