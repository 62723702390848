import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
export var LanguageContext = React.createContext({
    language: getLanguage(),
    setLanguage: function () { },
});
LanguageProvider.displayName = 'LanguageProvider';
export function LanguageProvider(props) {
    var _a = React.useState(getLanguage()), language = _a[0], setLanguage = _a[1];
    return (_jsx(LanguageContext.Provider, { value: { language: language, setLanguage: setLanguage }, children: props.children }));
}
export function useLanguage() {
    var state = React.useContext(LanguageContext);
    return state.language;
}
var languageStorageKey = 'language';
var supportedLanguages = ['cs', 'en'];
export function getLanguage() {
    var selectedLanguage = localStorage.getItem(languageStorageKey);
    if (selectedLanguage && supportedLanguages.includes(selectedLanguage)) {
        return selectedLanguage;
    }
    var userLang = navigator.language || navigator.userLanguage;
    if (userLang.toLowerCase().startsWith('cs')) {
        return 'cs';
    }
    return 'en';
}
export function setLanguage(language) {
    localStorage.setItem(languageStorageKey, language);
}
