import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import * as Utils from '_utils';
import * as Styles from './Modal.module.scss';
export function Modal(props) {
    var _a;
    var ref = React.useRef(null);
    React.useEffect(function () {
        var _a, _b, _c;
        if (props.open) {
            if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.open) {
                return;
            }
            (_b = ref.current) === null || _b === void 0 ? void 0 : _b.showModal();
        }
        else {
            (_c = ref.current) === null || _c === void 0 ? void 0 : _c.close();
        }
    }, [props.open]);
    var classNames = (_a = {},
        _a[Styles.wide] = props.size === 'wide',
        _a[Styles.full] = props.size === 'full',
        _a);
    return (_jsx("dialog", { ref: ref, onClose: props.onClose, className: Utils.classNames(classNames), children: _jsx(DialogContext.Provider, { value: { close: props.onClose }, children: props.children }) }));
}
export var DialogContext = React.createContext({});
