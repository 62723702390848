// extracted by mini-css-extract-plugin
var _1 = "src-components-common-SimpleComponents-module__container";
var _2 = "src-components-common-SimpleComponents-module__delimiter";
var _3 = "src-components-common-SimpleComponents-module__property";
var _4 = "src-components-common-SimpleComponents-module__value";
export { _1 as "container", _2 as "delimiter", _3 as "property", _4 as "value" }

    if(module.hot) {
      (function() {
        var localsJsonString = "{\"container\":\"src-components-common-SimpleComponents-module__container\",\"delimiter\":\"src-components-common-SimpleComponents-module__delimiter\",\"property\":\"src-components-common-SimpleComponents-module__property\",\"value\":\"src-components-common-SimpleComponents-module__value\"}";
        // 1728929521859
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  