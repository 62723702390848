import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as Common from '_common';
import * as Components from '_components';
export function SettingsDialog() {
    var dialogCtx = Common.useSettingsDialog();
    switch (dialogCtx.tab) {
        case Common.SettingsTabs.UserInfo:
            return _jsx(UserInfo, {});
        case Common.SettingsTabs.Login:
            return _jsx(Login, {});
        case Common.SettingsTabs.Theme:
            return _jsx(Theme, {});
        case Common.SettingsTabs.About:
            return _jsx(About, {});
        default:
            return _jsx(_Fragment, {});
    }
}
function Login() {
    return (_jsx(Dialog, { children: _jsx(Components.LoginForm, {}) }));
}
function UserInfo() {
    return (_jsxs(Dialog, { size: "wide", controls: _jsx(Components.LogoutButton, {}), children: [_jsx("h1", { children: _jsx(Components.UserName, {}) }), _jsx(Components.Property, { title: "Login", children: _jsx(Components.UserLogin, {}) }), _jsxs("section", { children: [_jsx("h2", { children: "Roles" }), _jsx(Components.UserRoleList, {})] })] }));
}
function Theme() {
    return (_jsx(Dialog, { children: _jsx(Components.UserThemeButtons, {}) }));
}
function About() {
    return (_jsxs(Dialog, { children: ["(C) Michal Fl\u00EDdr 2022 ", _jsx("br", {}), _jsx("small", { children: _jsx(Components.VersionInfo, {}) })] }));
}
function Dialog(props) {
    var dialogCtx = Common.useSettingsDialog();
    function closed() {
        dialogCtx.setTab(Common.SettingsTabs.None);
    }
    var innerControls = (_jsxs(Components.BtnGroup, { children: [props.controls, props.hideClose || (_jsx(Components.Button, { onClick: closed, children: _jsx(Components.MLT, { cs: "Zav\u0159\u00EDt", en: "Close" }) }))] }));
    return (_jsx(Components.Modal, { open: true, onClose: closed, size: props.size, children: _jsx(Components.DialogWidget, { title: "MichalF.cz", controls: innerControls, children: _jsx(Components.Container, { children: props.children }) }) }));
}
