var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import { ErrorPanel } from './ErrorPanel';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.resetError = function () {
            _this.setState({ hasError: false });
        };
        _this.state = { hasError: false };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        // Display fallback UI
        this.setState({ hasError: true, error: error, errorInfo: errorInfo });
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.renderErrorDetail();
        }
        return this.props.children || _jsx(_Fragment, {});
    };
    ErrorBoundary.prototype.renderErrorDetail = function () {
        var _a;
        if (!this.props.errorGui) {
            return (_jsxs(ErrorPanel, { children: [(_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message, this.details(false), _jsx("button", { onClick: this.resetError, children: "OK" })] }));
        }
        if (typeof this.props.errorGui === 'string') {
            return (_jsxs(ErrorPanel, { children: [this.props.errorGui, " ", _jsx("br", {}), this.details(true), _jsx("button", { onClick: this.resetError, children: "OK" })] }));
        }
        return this.props.errorGui;
    };
    ErrorBoundary.prototype.details = function (includeMessage) {
        var _a, _b, _c;
        if (Common.debugSettings.isDebug) {
            return (_jsxs(_Fragment, { children: [includeMessage && ((_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message), _jsx("pre", { children: (_b = this.state.errorInfo) === null || _b === void 0 ? void 0 : _b.componentStack })] }));
        }
        else
            return _jsx(_Fragment, { children: includeMessage && ((_c = this.state.error) === null || _c === void 0 ? void 0 : _c.message) });
    };
    return ErrorBoundary;
}(React.Component));
export { ErrorBoundary };
