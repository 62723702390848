export function parseTsx(tsx) {
    if (!tsx || tsx.length === 0) {
        return { type: 'array' };
    }
    var parser = new DOMParser();
    var document = parser.parseFromString(tsx, 'text/xml');
    if (document.getElementsByTagName('parsererror').length > 0) {
        console.error(document);
        throw Error('Invalid XML.');
    }
    return postProcesing(parseComponent(document.documentElement));
}
function parseComponent(node) {
    var _a, _b;
    var result = {
        type: node.nodeName,
        children: [],
        properties: {},
    };
    for (var i = 0; i < node.attributes.length; i++) {
        var attr = node.attributes[i];
        result.properties[attr.nodeName] = updateAttr(attr.nodeValue);
    }
    if (node.childNodes.length === 1 &&
        node.childNodes[0].nodeType === Node.TEXT_NODE) {
        result.properties['text'] = trimText(node.childNodes[0].nodeValue);
    }
    else {
        for (var i = 0; i < node.childNodes.length; i++) {
            var child = node.childNodes[i];
            if (child.nodeType === Node.ELEMENT_NODE) {
                (_a = result.children) === null || _a === void 0 ? void 0 : _a.push(parseComponent(child));
            }
            else if (child.nodeType === Node.TEXT_NODE) {
                (_b = result.children) === null || _b === void 0 ? void 0 : _b.push({
                    type: 'Text',
                    properties: {
                        text: trimText(child.nodeValue),
                    },
                });
            }
        }
    }
    return transformComponent(result);
}
function trimText(text) {
    if (!text) {
        return '';
    }
    var result = text.trim();
    if (result.length === 0) {
        return '';
    }
    if (text[0] === ' ') {
        result = ' ' + result;
    }
    if (text[text.length - 1] === ' ') {
        result = result + ' ';
    }
    return result;
}
function postProcesing(component) {
    var parts = [];
    var result = getParts(component, parts);
    if (result) {
        result = replaceParts(result, parts);
    }
    return result !== null && result !== void 0 ? result : { type: 'array' };
}
function transformComponent(component) {
    var type = component.type.toLowerCase();
    if (type === 'cs') {
        component.type = 'If';
        component.properties.culture = 'cs';
    }
    if (type === 'en') {
        component.type = 'If';
        component.properties.culture = 'en';
    }
    return component;
}
function updateAttr(attribute) {
    if (attribute.toLowerCase() === 'true') {
        return true;
    }
    if (attribute.toLowerCase() === 'false') {
        return false;
    }
    return attribute;
}
function getParts(component, parts) {
    var _a;
    var type = component.type.toLowerCase();
    if (type === 'component' || type === 'part') {
        component.type = 'array';
        parts.push(component);
        return null;
    }
    else {
        component.children = (_a = component.children) === null || _a === void 0 ? void 0 : _a.map(function (c) { return getParts(c, parts); }).filter(function (i) { return i; });
        return component;
    }
}
function replaceParts(component, parts) {
    var _a, _b, _c;
    var type = component.type.toLowerCase();
    if (type === 'include' || type === 'use') {
        var partName_1 = (_b = (_a = component.properties.component) !== null && _a !== void 0 ? _a : component.properties.part) !== null && _b !== void 0 ? _b : '';
        var part = parts.find(function (c) { return c.properties.name === partName_1; });
        return part !== null && part !== void 0 ? part : null;
    }
    else {
        component.children = (_c = component.children) === null || _c === void 0 ? void 0 : _c.map(function (c) { return replaceParts(c, parts); }).filter(function (i) { return i; });
        return component;
    }
}
