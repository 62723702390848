import React from 'react';
import * as Common from '_common';
import { AppModule } from './app/AppScreen';
import { IndexModule } from './modules/IndexModule';
var MinecraftModule = React.lazy(function () { return import(/* webpackChunkName: "mc" */ './modules/MinecraftModule'); });
var BlogModule = React.lazy(function () { return import(/* webpackChunkName: "blog" */ './modules/BlogModule'); });
export var appModules = [
    {
        id: 'Index',
        name: { en: 'Home', cs: 'Domů' },
        role: 'public',
        components: { module: IndexModule },
    },
    {
        id: 'Blog',
        name: 'Blog',
        role: 'Public',
        components: { module: BlogModule },
    },
    {
        id: 'Minecraft',
        name: 'Minecraft',
        role: 'Minecraft',
        components: { module: MinecraftModule },
        preferedLayout: Common.AppModulePreferedLayout.Minimal,
    },
    {
        id: 'Context',
        name: 'Context',
        role: 'Admin',
        components: { module: AppModule },
    },
    {
        id: 'Users',
        name: 'Users',
        role: 'Admin',
        components: { module: AppModule },
    },
    {
        id: 'Settings',
        name: 'Settings',
        role: 'Admin',
        components: { module: AppModule },
    },
];
export function getAppModule(id) {
    return appModules.find(function (m) { return m.id === id; });
}
