import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Common from '_common';
import * as Components from '_components';
export function IndexModule() {
    var user = Common.useUser();
    if (Common.userHaveRole(user, 'User')) {
        return _jsx(Dashboard, {});
    }
    else {
        return _jsx(PublicWelcomePage, {});
    }
}
function PublicWelcomePage() {
    return (_jsxs("div", { children: [_jsxs(Components.If, { culture: "cs", children: [_jsx("h1", { children: "V\u00EDtejte na str\u00E1nk\u00E1ch michalf.cz" }), _jsx("p", { children: "Ahoj, jmenuji se Michal a vy jste pr\u00E1v\u011B na\u0161li moje osobn\u00ED str\u00E1nky. \u017Diju v mal\u00E9m m\u011Bst\u011B v \u010Cesk\u00E9 Republice a \u017Eiv\u00EDm se jako program\u00E1tor. Tyhle str\u00E1nky jsem vytvo\u0159il, abych mohl p\u0159istupovat k moj\u00ED dom\u00E1c\u00ED automatizaci a abych mohl sd\u00EDlet n\u011Bkter\u00E9 m\u00E9 projekty a n\u00E1pady s rodinou a p\u0159\u00E1teli." })] }), _jsxs(Components.If, { culture: "en", children: [_jsx("h1", { children: "Welcome to michalf.cz" }), _jsx("p", { children: "Hi, Iam Michal and you just found my personal page. I live in a small town in Czech Republic and I work as a software developer. I created this page to be able to access my home automation and to share some projects and ideas with my family and friends." })] })] }));
}
function Dashboard() {
    return _jsx(Components.Dashboard, {});
}
