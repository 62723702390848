import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Utils from '_utils';
import * as Common from '_common';
import * as Styles from './ProgressBar.module.scss';
export var ProgressType;
(function (ProgressType) {
    ProgressType[ProgressType["Percent"] = 0] = "Percent";
    ProgressType[ProgressType["Value"] = 1] = "Value";
    ProgressType[ProgressType["Blinds"] = 2] = "Blinds";
})(ProgressType || (ProgressType = {}));
export function Progress(props) {
    var _a;
    var style = {
        width: (props.value / props.max) * 100 + '%',
    };
    if (props.type === ProgressType.Blinds) {
        style = {
            height: (props.value / props.max) * 100 + '%',
        };
    }
    function createSigns(type) {
        var signs = [];
        function addSign(value, text) {
            var style = props.type === ProgressType.Blinds
                ? { top: value + '%' }
                : { left: value + '%' };
            signs.push(_jsx("div", { className: Styles.sign, style: style, children: _jsx("span", { children: text }) }, value));
        }
        if (props.customSigns) {
            props.customSigns.forEach(function (sign) {
                var _a;
                var value = (sign.value / props.max) * 100;
                addSign(value, (_a = sign.text) !== null && _a !== void 0 ? _a : '');
            });
            return signs;
        }
        if (type === ProgressType.Percent) {
            for (var i = 1; i < 10; i++) {
                addSign(i * 10, i * 10 + '%');
            }
        }
        if (type === ProgressType.Value) {
        }
        if (type === ProgressType.Blinds) {
            addSign(50, '50%');
        }
        return signs;
    }
    var classNames = (_a = {},
        _a[Styles.type_percent] = props.type === ProgressType.Percent,
        _a[Styles.type_value] = props.type === ProgressType.Value,
        _a[Styles.type_blinds] = props.type === ProgressType.Blinds,
        _a);
    var signs = createSigns(props.type);
    classNames = Common.WithCommonProps(props, classNames);
    function onMouseUp(e) {
        if (props.onClick) {
            var rect = e.currentTarget.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var value = Math.round((x / rect.width) * props.max);
            props.onClick(value);
        }
    }
    return (_jsxs(Common.Component, { name: 'progress', className: Utils.classNames(classNames), onMouseUp: onMouseUp, children: [_jsx("div", { className: Styles.bar, style: style }), signs] }));
}
