var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import * as Formik from 'formik';
import * as Common from '_common';
import * as Components from '_components';
import * as Utils from '_utils';
import { useNavigate } from 'react-router';
export function userComponentRegister(type) {
    switch (type) {
        case 'name':
            return UserName;
        case 'login':
            return UserLogin;
        case 'rolelist':
            return UserRoleList;
        case 'languagebuttons':
            return UserLanguageButtons;
        case 'themebuttons':
            return UserThemeButtons;
        case 'loginform':
            return LoginForm;
        case 'logoutbutton':
            return LogoutButton;
    }
}
export function UserName() {
    var user = Common.useUser();
    return _jsx(_Fragment, { children: user.name });
}
export function UserLogin() {
    var user = Common.useUser();
    return _jsx(_Fragment, { children: user.login });
}
export function UserRoleList() {
    function getSource(role) {
        if (role && user.roleSources) {
            return user.roleSources[role];
        }
    }
    function formatRoleInfo(role, source) {
        var _a;
        var isPrimary = source === 'primary';
        var styles = Utils.classNames((_a = {},
            _a['gray'] = !isPrimary,
            _a));
        var sourceInfo;
        if (source === 'public') {
            sourceInfo = (_jsx(Components.MLT, { en: "This role is automatically assigned to all users." }));
        }
        else if (source === 'auth') {
            sourceInfo = (_jsx(Components.MLT, { en: "This role is automatically assigned to authenticated users." }));
        }
        else if (source === 'primary' || !source) {
            sourceInfo = '';
        }
        else {
            sourceInfo = (_jsx(Components.MLT, { en: "This role is automatically assigned base on rule with name ".concat(source, ".") }));
        }
        return (_jsxs("li", { className: styles, children: [role, " ", _jsx("small", { children: sourceInfo })] }, role));
    }
    var user = Common.useUser();
    return _jsx(_Fragment, { children: user.roles.sort().map(function (i) { return formatRoleInfo(i, getSource(i)); }) });
}
export function UserLanguageButtons() {
    var languageContext = React.useContext(Common.LanguageContext);
    return (_jsxs(Components.BtnGroup, { children: [_jsx(Components.Button, { isActive: languageContext.language === 'en', onClick: function () { return languageContext.setLanguage('en'); }, children: "English" }), _jsx(Components.Button, { isActive: languageContext.language === 'cs', onClick: function () { return languageContext.setLanguage('cs'); }, children: "\u010Cesky" })] }));
}
export function UserThemeButtons() {
    var theme = Common.useTheme();
    // TODO set isActive on selected button
    return (_jsxs(Components.BtnGroup, { children: [_jsx(Components.Button, { isDisabled: true, onClick: function () {
                    return theme.setTheme({
                        name: Common.ThemeDefault,
                        variant: Common.ThemeLight,
                    });
                }, children: "Light" }), _jsx(Components.Button, { isDisabled: true, onClick: function () {
                    return theme.setTheme({
                        name: Common.ThemeDefault,
                        variant: Common.ThemeDark,
                    });
                }, children: "Dark" }), _jsx(Components.Button, { onClick: function () {
                    return theme.setTheme({
                        name: Common.ThemeLcars,
                        variant: Common.ThemeDark,
                    });
                }, children: "Lcars" })] }));
}
export function LoginForm() {
    var _this = this;
    var lang = Common.useLanguage();
    var userCtx = Common.useUser();
    var settingsDialog = Common.useSettingsDialog();
    var navigate = useNavigate();
    var validate = function (values) {
        var errors = {};
        if (!values.login) {
            errors.login = Common.LocalText({
                en: 'Login is required.',
                cs: 'Uživatelské jméno je povinné.',
            }, lang);
        }
        if (!values.password) {
            errors.password = Common.LocalText({ en: 'Password is required.', cs: 'Heslo je povinné.' }, lang);
        }
        return errors;
    };
    var onSubmit = function (values_1, _a) { return __awaiter(_this, [values_1, _a], void 0, function (values, _b) {
        var user, e_1;
        var setSubmitting = _b.setSubmitting, setErrors = _b.setErrors;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setSubmitting(true);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Common.TryLogin(values)];
                case 2:
                    user = _c.sent();
                    userCtx.setUser(user);
                    settingsDialog.setTab(Common.SettingsTabs.None);
                    navigate('/');
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _c.sent();
                    setErrors({
                        login: Common.LocalText({
                            en: 'Login or password is not correct.',
                            cs: 'Jméno nebo heslo nejsou správné.',
                        }, lang),
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setSubmitting(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Formik.Formik, { initialValues: {
            login: '',
            password: '',
        }, validate: validate, onSubmit: onSubmit, children: function (_a) {
            var values = _a.values, errors = _a.errors, touched = _a.touched, handleChange = _a.handleChange, handleBlur = _a.handleBlur, handleSubmit = _a.handleSubmit, isSubmitting = _a.isSubmitting;
            return (_jsxs("form", { onSubmit: handleSubmit, children: [_jsx(Components.TextInput, { label: { cs: 'Jméno', en: 'Login' }, name: "login", value: values.login, onChange: handleChange, onBlur: handleBlur, isValid: touched.login && !errors.login, isInvalid: !!errors.login, validationMessage: touched.login ? errors.login : undefined }), _jsx(Components.PasswordInput, { label: { cs: 'Heslo', en: 'Password' }, name: "password", value: values.password, onChange: handleChange, onBlur: handleBlur, isValid: touched.password && !errors.password, isInvalid: !!errors.password, validationMessage: touched.password ? errors.password : undefined }), _jsx(Components.SubmitButton, { children: "Ok" })] }));
        } }));
}
export function LogoutButton() {
    var state = Common.useUser();
    var dialog = Common.useSettingsDialog();
    var navigate = useNavigate();
    function logout() {
        return __awaiter(this, void 0, void 0, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Common.Logout()];
                    case 1:
                        user = _a.sent();
                        state.setUser(user);
                        dialog.setTab(Common.SettingsTabs.None);
                        sessionStorage.clear();
                        navigate('/');
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsx(Components.Button, { onClick: logout, children: _jsx(Components.MLT, { cs: "Odhl\u00E1sit se", en: "Logout" }) }));
}
