import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as Common from '_common';
import * as Components from '_components';
export function TimeSmallRender(component, index) {
    return _jsx(TimeWidget, { full: false });
}
export function TimeFullRender(component, index) {
    return _jsx(TimeWidget, { full: true });
}
export function TimeWidget(props) {
    var _a;
    var context = Common.useSpaContext();
    var dayInfo = (_a = context === null || context === void 0 ? void 0 : context.state) === null || _a === void 0 ? void 0 : _a.day;
    var details = [];
    if (!(dayInfo === null || dayInfo === void 0 ? void 0 : dayInfo.holiday) || (dayInfo === null || dayInfo === void 0 ? void 0 : dayInfo.holiday) === '-') {
        details.push(_jsx(Components.Property, { title: _jsx(Components.MLT, { cs: "St\u00E1tek m\u00E1", en: "Name day" }), children: dayInfo === null || dayInfo === void 0 ? void 0 : dayInfo.dayName }, "1"));
    }
    else {
        details.push(_jsx(Components.Property, { title: _jsx(Components.MLT, { cs: "Dnes je", en: "Holiday" }), children: dayInfo === null || dayInfo === void 0 ? void 0 : dayInfo.holiday }, "2"));
    }
    if (props.full) {
        details.push(_jsx(Components.Property, { title: _jsx(Components.MLT, { cs: "Pracovn\u00ED den", en: "Is working day" }), children: _jsx(Components.BooleanText, { value: dayInfo.isWorkingDay }) }, "3"));
    }
    var render = function () {
        var date = new Date();
        return (_jsxs(_Fragment, { children: [_jsx("b", { children: getDayString(date) }), _jsx("h1", { children: date.toLocaleTimeString() }), details] }));
    };
    return _jsx(Components.Refresh, { afterMs: 500, children: render });
}
function getDayString(date) {
    var dayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    var dayOfWeek = dayNames[date.getDay()];
    return dayOfWeek + ' ' + date.toLocaleDateString();
}
