var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { useNavigate } from 'react-router';
import * as Utils from '_utils';
import { debugComponentRegister } from '_componentRegister';
import * as Components from '_components';
import * as Styles from './DebugPage.module.scss';
export function DebugScreen(props) {
    var debugRegister = debugComponentRegister();
    var navigate = useNavigate();
    var selectedId = 'Index';
    var tabs = [];
    tabs.push({
        type: 'tab',
        properties: { id: 'Index', title: 'Index' },
        children: [{ type: 'Debug_Index' }],
    });
    function getGroup(value) {
        if (value.indexOf('#') > 0) {
            return value.split('#')[1];
        }
        return undefined;
    }
    function getValue(value) {
        if (value.indexOf('#') > 0) {
            return value.split('#')[0];
        }
        return value;
    }
    for (var key in debugRegister) {
        var value = debugRegister[key];
        tabs.push({
            type: 'tab',
            properties: { id: key, title: key, group: getGroup(value) },
            children: [
                {
                    type: 'Debug_controls',
                    children: [{ type: 'debug_' + getValue(value) }],
                },
            ],
        });
    }
    if (props.cmp && debugRegister[props.cmp]) {
        // TODO specific debug page
        selectedId = props.cmp;
    }
    var extraButtons = (_jsx(Components.BtnGroup, { children: _jsx(Components.Button, { onClick: function () { return navigate('/'); }, children: _jsx(Components.MLT, { cs: "Zav\u0159\u00EDt", en: "Close" }) }) }));
    return (_jsx(Components.TabsWidget, { tabs: tabs, selectedId: selectedId, setSelected: function (id) { return navigate('/Debug/' + id); }, title: "Debug page", extraBtns: extraButtons }));
}
export function Index(component, index) {
    var navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Html elements" }), _jsxs(Components.BtnGroup, { type: Components.BtnGroupType.Grid, children: [_jsx(Components.Button, { onClick: function () { return navigate('/Debug/Index'); }, children: "Fonts" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/Index'); }, children: "Colors" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/title'); }, children: "Title" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/Link'); }, children: "Links" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/List'); }, children: "Lists" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/Layout'); }, children: "Layout" })] }), _jsx("h1", { children: "Simple components" }), _jsxs(Components.BtnGroup, { type: Components.BtnGroupType.Grid, children: [_jsx(Components.Button, { onClick: function () { return navigate('/Debug/Button'); }, children: "Buttons" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/Index'); }, children: "Dropdown" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/Editor'); }, children: "Editor" })] }), _jsx("h1", { children: "Complex components" }), _jsxs(Components.BtnGroup, { type: Components.BtnGroupType.Grid, children: [_jsx(Components.Button, { onClick: function () { return navigate('/Debug/Widget'); }, children: "Widgets" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/Tabs'); }, children: "Tabs" }), _jsx(Components.Button, { onClick: function () { return navigate('/Debug/Presentation'); }, children: "Presentation" })] })] }));
}
export function DebugControls(component, index) {
    var _a = useToggles({
        errorOverlay: false,
    }), state = _a[0], toggleButtons = _a[1];
    return (_jsxs("div", { className: Styles.screen_root, children: [_jsxs("div", { className: Styles.context_toggles, children: ["Context:", toggleButtons] }), _jsx("div", { className: Styles.content + ' ' + Utils.classNames(state), children: Components.RenderChildren(component.children) })] }));
}
export function DebugPage(props) {
    return (_jsxs("div", { className: Styles.debug_page, children: [_jsx("div", { className: Styles.controls, children: props.controls }), _jsx("div", { className: Styles.inner_content, children: props.children })] }));
}
export function useToggles(value) {
    var _a = React.useState(value), state = _a[0], setState = _a[1];
    function toggle(name) {
        var newState = __assign({}, state);
        newState[name] = !newState[name];
        setState(newState);
    }
    function toggleButtons() {
        var result = [];
        var _loop_1 = function (key) {
            result.push(_jsx(Components.Button, { isActive: state[key], onClick: function () { return toggle(key); }, children: key }, key));
        };
        for (var key in state) {
            _loop_1(key);
        }
        return result;
    }
    return [state, toggleButtons()];
}
export function useOneOf(values) {
    var _a = React.useState(values[0]), state = _a[0], setState = _a[1];
    function toggle(name) {
        setState(name);
    }
    function toggleButtons() {
        var result = [];
        var _loop_2 = function (key) {
            result.push(_jsx(Components.Button, { isActive: state === key, onClick: function () { return toggle(key); }, children: key }, key));
        };
        for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
            var key = values_1[_i];
            _loop_2(key);
        }
        return result;
    }
    return [state, toggleButtons()];
}
