export function createPublicUser() {
    return {
        login: 'public',
        isPublic: true,
        roles: ['Public'],
    };
}
export function userHaveRole(user, role) {
    if (!role) {
        return true;
    }
    if (!user || !user.roles) {
        return false;
    }
    if (role.startsWith('!')) {
        // TODO is this correct?
        return !userHaveRole(user, role.replace('!', ''));
    }
    if (user.roles.indexOf('*') >= 0) {
        return true;
    }
    var lowerRole = role.toLowerCase();
    return (user.roles.findIndex(function (value) { return value.toLowerCase() === lowerRole; }) >= 0);
}
export function isPublicUser(user) {
    var _a;
    if (!user) {
        return true;
    }
    return (_a = user.isPublic) !== null && _a !== void 0 ? _a : true;
}
