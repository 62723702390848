var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import { Gap } from '_components';
function createDefaultState() {
    return {
        overlay: { type: Common.OverlayType.Null },
        dialog: Common.SettingsTabs.None,
        placeHolders: {},
    };
}
export var ScreenContext = React.createContext(__assign(__assign({}, createDefaultState()), { setScreen: function () { } }));
ScreenContext.displayName = 'ScreenContext';
export function ScreenStateProvider(props) {
    var _a = React.useState(createDefaultState()), state = _a[0], setState = _a[1];
    return (_jsx(ScreenContext.Provider, { value: __assign(__assign({}, state), { setScreen: setState }), children: props.children }));
}
export function useSettingsDialog() {
    var context = React.useContext(ScreenContext);
    return {
        tab: context.dialog,
        setTab: function (tab) {
            var newValue = {
                dialog: tab,
                overlay: context.overlay,
                placeHolders: context.placeHolders,
            };
            context.setScreen(newValue);
        },
    };
}
export function useOverlay() {
    var context = React.useContext(ScreenContext);
    return {
        overlay: context.overlay,
        setOverlay: function (overlay) {
            var newValue = {
                dialog: context.dialog,
                overlay: overlay,
                placeHolders: context.placeHolders,
            };
            context.setScreen(newValue);
        },
    };
}
function setPlaceHolder(context, name, value, id) {
    var newValue = __assign({}, context);
    if (!newValue.placeHolders[name]) {
        newValue.placeHolders[name] = {};
    }
    if (id === undefined) {
        id = 'default';
    }
    newValue.placeHolders[name][id] = value;
    context.setScreen(newValue);
}
export function ModuleNav(props) {
    return SetPlaceholder({
        name: 'ModuleNav',
        children: props.children,
        id: props.id,
    });
}
export function ContextButtons(props) {
    return SetPlaceholder({
        name: 'ContextButtons',
        children: props.children,
        id: props.id,
    });
}
function SetPlaceholder(props) {
    var context = React.useContext(ScreenContext);
    React.useEffect(function () {
        setPlaceHolder(context, props.name, props.children, props.id);
        return function () {
            setPlaceHolder(context, props.name, undefined, props.id);
        };
    }, [props.children]);
    return _jsx(_Fragment, {});
}
export function Placeholder(props) {
    var context = React.useContext(ScreenContext);
    var result = context.placeHolders[props.name];
    if (result === undefined) {
        return _jsx(_Fragment, {});
    }
    var collection = [];
    var sortedKeys = Object.keys(result).sort();
    for (var _i = 0, sortedKeys_1 = sortedKeys; _i < sortedKeys_1.length; _i++) {
        var id = sortedKeys_1[_i];
        if (result[id] !== undefined) {
            if (collection.length > 0) {
                collection.push(_jsx(Gap, {}, 'gap' + id));
            }
            collection.push(result[id]);
        }
    }
    return _jsx(_Fragment, { children: collection });
}
