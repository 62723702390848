import * as Components from '_components';
import * as SimpleComponents from './components/common/SimpleComponents';
import { userComponentRegister } from './components/common/UserComponents';
import { textComponentRegister } from './components/common/TextComponents';
import { minecraftComponentRegister } from './components/minecraft/MinecraftComponents';
import { houseComponentRegister } from './components/house/HouseComponents';
import { widgetsComponentRegister } from './components/widgets/Components';
import * as DebugPage from './app/DebugPage';
import * as Tabs from './components/common/Tabs';
export function componentRegister(type) {
    var typeParts = splitTypeName(type);
    if (typeParts.isDebug) {
        switch (typeParts.type) {
            case 'index':
                return DebugPage.Index;
            case 'controls':
                return DebugPage.DebugControls;
        }
    }
    if (typeParts.group) {
        switch (typeParts.group) {
            case 'text':
                return textComponentRegister(typeParts.type);
            case 'user':
                return userComponentRegister(typeParts.type);
            case 'minecraft':
                return minecraftComponentRegister(typeParts.type);
            case 'house':
                return houseComponentRegister(typeParts.type);
            case 'widgets':
                return widgetsComponentRegister(typeParts.type);
        }
    }
    else {
        switch (typeParts.type) {
            case 'article':
                return SimpleComponents.Article;
            case 'section':
                return SimpleComponents.Section;
            case 'detail':
                return SimpleComponents.Details;
            case 'mlt':
                return SimpleComponents.MLTRender;
            case 'if':
                return SimpleComponents.IfRender;
            case 'title':
                return SimpleComponents.TitleRender;
            case 'content':
                return SimpleComponents.ArticleContent;
            case 'list':
                return SimpleComponents.List;
            case 'item':
                return SimpleComponents.Item;
            case 'property':
                return SimpleComponents.PropertyRender;
            case 'versioninfo':
                return SimpleComponents.VersionInfoRender;
            case 'button':
                return typeParts.isDebug ? Components.ButtonsDebug : undefined;
            case 'navbutton':
                return typeParts.isDebug
                    ? Components.ButtonsDebug
                    : Components.NavButtonRender;
            case 'hr':
                return SimpleComponents.Hr;
            case 'tabs':
                return typeParts.isDebug ? Tabs.TabsDebug : Tabs.Tabs;
            case 'layout':
                return typeParts.isDebug ? Components.LayoutDebug : undefined;
            case 'widget':
                return typeParts.isDebug
                    ? Components.WidgetDebug
                    : Components.WidgetRender;
            case 'editor':
                return typeParts.isDebug ? Components.EditorDebug : undefined;
            case 'dashboard':
                return Components.DashboardRender;
            case 'row':
                return Components.RowRender;
            case 'code':
                return Components.CodeRender;
        }
    }
}
export function debugComponentRegister() {
    return {
        Layout: 'layout',
        Widget: 'widget',
        Tabs: 'tabs',
        Title: 'title#Text',
        Link: 'link#Text',
        List: 'list#Text',
        Button: 'button#Interactive',
        Dropdown: 'dropdown#Interactive',
        Editor: 'editor#Interactive',
    };
}
var simpleNames = {
    b: 'text_bold',
    i: 'text_italic',
    u: 'text_underline',
    p: 'section',
    loginform: 'user_loginform',
};
function splitTypeName(type) {
    var isDebug = type.startsWith('debug_');
    if (isDebug) {
        type = type.replace('debug_', '');
    }
    if (simpleNames[type]) {
        type = simpleNames[type];
    }
    if (type.indexOf('_') > 0) {
        var parts = type.split('_');
        return { group: parts[0], type: parts[1], isDebug: isDebug };
    }
    return { type: type, isDebug: isDebug };
}
