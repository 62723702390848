import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
export function RenderSpaContextVisualisationSmall(component, index) {
    return _jsx("h1", { children: "Maximize to see" });
}
export function RenderSpaContextVisualisationFull(component, index) {
    return _jsx(SpaContextVisualizer, {});
}
export function SpaContextVisualizer() {
    var spaContext = Common.useSpaContext();
    return _jsx(ContextItem, { obj: spaContext, path: null });
}
export function ContextItem(props) {
    var keys = Object.keys(props.obj);
    if (keys.length === 0) {
        return _jsxs("div", { children: ["Empty", JSON.stringify(props.obj)] });
    }
    return (_jsx("div", { children: _jsx(_Fragment, { children: _jsx("ul", { children: keys.map(function (k) {
                    var path = props.path ? "".concat(props.path, ".").concat(k) : k;
                    return (_jsxs("li", { children: [_jsxs("span", { children: [k, " : "] }), _jsx(ContextItemValue, { value: props.obj[k], path: path })] }, k));
                }) }) }) }));
}
export function ContextItemValue(props) {
    var _a = React.useState(true), collapsed = _a[0], setCollapsed = _a[1];
    if (typeof props.value === 'object') {
        return (_jsxs(_Fragment, { children: [collapsed && (_jsx("a", { href: "#", onClick: function () { return setCollapsed(false); }, children: "+" })), !collapsed && (_jsxs(_Fragment, { children: [_jsx("a", { href: "#", onClick: function () { return setCollapsed(true); }, children: "-" }), _jsx(ContextItem, { obj: props.value, path: props.path })] }))] }));
    }
    return (_jsxs("span", { children: [props.value, " ", _jsxs("small", { children: ["(", props.path, ")"] })] }));
}
