import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import * as Styles from './EditForm.module.scss';
export function SourceEditor(props) {
    var _a;
    var textAreaRef = React.useRef(null);
    var sourceRef = React.useRef(null);
    function keyPress(event) {
        var textArea = event.target;
        if (!textArea) {
            return false;
        }
        function insert(text) {
            var start = textArea.selectionStart;
            var end = textArea.selectionEnd;
            textArea.value =
                textArea.value.substring(0, start) +
                    text +
                    textArea.value.substring(end);
            textArea.selectionStart = textArea.selectionEnd =
                start + text.length;
            props.setSource(textArea.value);
        }
        if (event.key === 'Enter') {
            var textLines = textArea.value
                .substring(0, textArea.selectionStart)
                .split('\n');
            var currentLine = textLines[textLines.length - 1];
            var begin = '\n' + currentLine.replace(currentLine.trimStart(), '');
            insert(begin);
            event.preventDefault();
            return true;
        }
        if (event.key === 'Tab') {
            insert('    ');
            event.preventDefault();
            return true;
        }
    }
    function handleScroll(event) {
        if ((textAreaRef === null || textAreaRef === void 0 ? void 0 : textAreaRef.current) && (sourceRef === null || sourceRef === void 0 ? void 0 : sourceRef.current)) {
            sourceRef.current.scrollTop = textAreaRef.current.scrollTop;
            sourceRef.current.scrollLeft = textAreaRef.current.scrollLeft;
        }
    }
    //TODO handle scroll in read only mode
    return (_jsxs("div", { className: Styles.source_editor, children: [props.allowEdit && (_jsx("textarea", { onChange: function (event) { return props.setSource(event.target.value); }, onKeyDown: keyPress, onScroll: handleScroll, value: props.source, wrap: "hard", ref: textAreaRef })), _jsx("pre", { ref: sourceRef, children: _jsx("code", { children: renderSourceEditor((_a = props.source) !== null && _a !== void 0 ? _a : '') }) })] }));
}
function renderSourceEditor(source) {
    var parts = split(source);
    var result = [];
    var defaultColor = '#D4D4C8';
    var signColor = '#808080';
    var elementColor = '#569CD6';
    var attributeNameColor = '#9CDCFD';
    var attributeValueColor = '#CE9178';
    var color = 'white';
    var isProperty = false;
    var inElement = false;
    var key = 0;
    for (var _i = 0, parts_1 = parts; _i < parts_1.length; _i++) {
        var token = parts_1[_i];
        var tokenHandled = false;
        if (!isProperty && token === '"') {
            isProperty = true;
            color = attributeValueColor;
            tokenHandled = true;
        }
        if (token === '<' || token === '>' || token === '?' || token === '/') {
            color = signColor;
        }
        if (token === ' ') {
            if (inElement) {
                color = attributeNameColor;
            }
            else {
                color = defaultColor;
            }
        }
        result.push(_jsx("span", { style: { color: color }, children: token }, key));
        key++;
        if (tokenHandled) {
            continue;
        }
        if (token === '<') {
            color = elementColor;
            inElement = true;
        }
        if (isProperty && token === '"') {
            isProperty = false;
            color = defaultColor;
        }
        if (token === '>') {
            color = defaultColor;
            inElement = false;
        }
        if (token === '?' || token === '/') {
            color = elementColor;
        }
    }
    return result;
}
function split(source) {
    var result = [];
    var current = '';
    var splitChars = ['<', '>', '"', '=', ' ', '?', '/'];
    for (var i = 0; i < source.length; i++) {
        var char = source.charAt(i);
        if (splitChars.indexOf(char) >= 0) {
            result.push(current);
            current = '';
            result.push(char);
            continue;
        }
        current += char;
    }
    return result;
}
