var PromiseCache = /** @class */ (function () {
    function PromiseCache(getDataFunction, keyPrefix, updateEventName) {
        this.pageInfoCachePromisses = {};
        this.getData = getDataFunction;
        this.keyPrefix = keyPrefix;
        this.updateEventName = updateEventName;
    }
    PromiseCache.prototype.doRequest = function (id, force, usedId) {
        var _this = this;
        if (force === void 0) { force = false; }
        if (!force) {
            var item = this.getItemFromStorage(id);
            if (item) {
                return Promise.resolve(item);
            }
        }
        if (!this.pageInfoCachePromisses[id]) {
            this.pageInfoCachePromisses[id] = this.getData(id).then(function (pages) {
                if (!Array.isArray(pages)) {
                    _this.setItemToStorage(id, pages);
                }
                else {
                    for (var _i = 0, pages_1 = pages; _i < pages_1.length; _i++) {
                        var item = pages_1[_i];
                        var itemId = item.id;
                        if (!itemId) {
                            itemId = id;
                        }
                        _this.setItemToStorage(itemId, item);
                    }
                }
                delete _this.pageInfoCachePromisses[id];
            });
        }
        return new Promise(function (resolve, reject) {
            _this.pageInfoCachePromisses[id].then(function () {
                var pageInfo = _this.getItemFromStorage(usedId !== null && usedId !== void 0 ? usedId : id);
                if (pageInfo) {
                    resolve(pageInfo);
                }
                else {
                    console.error('Page info not found in cache', usedId !== null && usedId !== void 0 ? usedId : id);
                    reject();
                }
            });
        });
    };
    PromiseCache.prototype.getItemFromStorage = function (id) {
        var data = sessionStorage.getItem(this.getPageInfoKey(id));
        if (data) {
            return JSON.parse(data);
        }
    };
    PromiseCache.prototype.setItemToStorage = function (id, info) {
        var key = this.getPageInfoKey(id);
        sessionStorage.setItem(key, JSON.stringify(info));
        document.dispatchEvent(new CustomEvent(this.updateEventName, { detail: info }));
    };
    PromiseCache.prototype.getPageInfoKey = function (id) {
        return this.keyPrefix + id.toLowerCase();
    };
    return PromiseCache;
}());
export { PromiseCache };
