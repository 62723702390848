var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as Utils from '_utils';
import { LoadingPage } from './../app/LoadingPage';
import * as React from 'react';
export var UserContext = React.createContext(__assign(__assign({}, getPublicUser()), { setUser: function () { } }));
UserContext.displayName = 'UserContext';
export function UserProvider(props) {
    var _a = React.useState(getPublicUser()), state = _a[0], setState = _a[1];
    React.useEffect(function () {
        // try to load user info at startup
        InitUserData(state)
            .then(function (user) {
            setState(user);
        })
            .catch();
    }, []);
    Utils.useTimer(1000 * 60 * 5, refreshUserInfo);
    function refreshUserInfo() {
        return __awaiter(this, void 0, void 0, function () {
            var currentUserInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (state.isPublic) {
                            // user was not authenticated, skip this
                            return [2 /*return*/];
                        }
                        console.info('Updating user info.');
                        return [4 /*yield*/, LoadUserInfo()];
                    case 1:
                        currentUserInfo = _a.sent();
                        if (!currentUserInfo.isPublic) return [3 /*break*/, 4];
                        console.info('User session was terminated. Trying automatic refresh.');
                        return [4 /*yield*/, TryAutoLogin()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, LoadUserInfo()];
                    case 3:
                        currentUserInfo = _a.sent();
                        _a.label = 4;
                    case 4:
                        setState(currentUserInfo);
                        return [2 /*return*/];
                }
            });
        });
    }
    if (!userIsLoaded(state)) {
        // TODO return loading screen
        return _jsx(LoadingPage, { loadingState: "loading user info" });
    }
    return (_jsx(UserContext.Provider, { value: __assign(__assign({}, state), { setUser: setState }), children: props.children }));
}
function userIsLoaded(user) {
    if (user && user.lastUpdate) {
        return true;
    }
    return false;
}
export function InitUserData(currentUser) {
    return __awaiter(this, void 0, void 0, function () {
        var lastUpdate, infoAge, fiveMinutes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    lastUpdate = currentUser.lastUpdate;
                    if (!lastUpdate) return [3 /*break*/, 3];
                    infoAge = new Date().getTime() - lastUpdate.getTime();
                    fiveMinutes = 1000 * 60 * 5;
                    if (!(infoAge > fiveMinutes)) return [3 /*break*/, 2];
                    return [4 /*yield*/, LoadUserInfo()];
                case 1:
                    currentUser = _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/, currentUser];
                case 3: 
                // no user info, try to auto login
                return [4 /*yield*/, TryAutoLogin()];
                case 4:
                    // no user info, try to auto login
                    _a.sent();
                    return [4 /*yield*/, LoadUserInfo()];
                case 5: 
                // load complete user info after auto login
                return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function useUser() {
    return React.useContext(UserContext);
}
export function LoadUserInfo() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            sessionStorage.clear();
            return [2 /*return*/, DoLoadUserInfo()];
        });
    });
}
function DoLoadUserInfo() {
    return __awaiter(this, void 0, void 0, function () {
        var userInfo, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Utils.Ajax.get('/api/auth/userInfo')];
                case 1:
                    userInfo = (_b.sent());
                    userInfo.lastUpdate = new Date();
                    return [2 /*return*/, userInfo];
                case 2:
                    _a = _b.sent();
                    return [2 /*return*/, getPublicUser()];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function getPublicUser() {
    return { isPublic: true, roles: [] };
}
export function TryLogin(values) {
    return __awaiter(this, void 0, void 0, function () {
        var credentials;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Utils.Ajax.post('/api/auth/login', values)];
                case 1:
                    _a.sent();
                    if (!navigator.credentials) return [3 /*break*/, 3];
                    credentials = new PasswordCredential({
                        id: values.login,
                        type: 'password',
                        password: values.password,
                    });
                    return [4 /*yield*/, navigator.credentials.store(credentials)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [4 /*yield*/, LoadUserInfo()];
                case 4: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function Logout() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Utils.Ajax.post('/api/auth/logout')];
                case 1:
                    _a.sent();
                    if (!navigator.credentials) return [3 /*break*/, 3];
                    return [4 /*yield*/, navigator.credentials.preventSilentAccess()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [4 /*yield*/, LoadUserInfo()];
                case 4: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function TryAutoLogin() {
    return __awaiter(this, void 0, void 0, function () {
        var cred, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(navigator.credentials && window.PasswordCredential)) return [3 /*break*/, 5];
                    return [4 /*yield*/, getCredentialsPromise()];
                case 1:
                    cred = _b.sent();
                    window.credentialPromise = undefined;
                    if (!cred) return [3 /*break*/, 5];
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, Utils.Ajax.post('/api/auth/login', {
                            login: cred.id,
                            password: cred.password,
                        })];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    _a = _b.sent();
                    console.warn('Autologin failed.');
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
function getCredentialsPromise() {
    // this is here to deal with react strict mode, browser does not like this request to be fired twice
    if (!window.credentialPromise) {
        window.credentialPromise = navigator.credentials.get({
            password: true,
            unmediated: true,
        });
    }
    return window.credentialPromise;
}
