import { useSearchParams } from 'react-router-dom';
export function useParam(name) {
    var _a = useSearchParams(), getParam = _a[0], setParams = _a[1];
    var paramValue = getParam.get(name);
    function setParamValue(value) {
        setParams(function (prev) {
            if (value) {
                prev.set(name, value);
            }
            else {
                prev.delete(name);
            }
            return prev;
        });
    }
    return [paramValue, setParamValue];
}
