var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import * as Components from '_components';
export function Tabs2(props) {
    var _a = React.useState(''), selectedId = _a[0], setSelectedId = _a[1];
    var allTabs = React.Children.map(props.children, function (child) {
        if (React.isValidElement(child) && child.type === Tab2) {
            return { id: child.props.id, title: child.props.title };
        }
    });
    React.useEffect(function () {
        if (props.selectedId) {
            setSelectedId(props.selectedId);
        }
        else if (allTabs.length > 0) {
            setSelectedId(allTabs[0].id);
        }
    }, [props.selectedId]);
    return (_jsxs(Components.Widget, { children: [_jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Top, type: Components.WidgetSideType.Big, children: _jsx(Components.Layout, { type: Components.LayoutType.HorizontalStack, children: _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Main, children: _jsx(Components.BtnGroup, { children: allTabs.map(function (tab) { return (_jsx(Components.Button, { onClick: function () { return setSelectedId(tab.id); }, isActive: tab.id === selectedId, children: _jsx(Components.MLT, { text: tab.title }) }, tab.id)); }) }) }) }) }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Bottom, type: Components.WidgetSideType.Big }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Content, children: React.Children.map(props.children, function (child) {
                    if (React.isValidElement(child) && child.type === Tab2) {
                        if (child.props.id === selectedId) {
                            return child;
                        }
                    }
                    else {
                        return child;
                    }
                }) })] }));
}
export function Tab2(props) {
    return _jsx(_Fragment, { children: props.children });
}
export function TabsContent(props) {
    var _a;
    for (var _i = 0, _b = props.tabs; _i < _b.length; _i++) {
        var tab = _b[_i];
        if (((_a = tab.properties) === null || _a === void 0 ? void 0 : _a.id) === props.selectedId) {
            return Components.RenderChildren(tab.children);
        }
    }
    if (props.tabs.length > 0) {
        return Components.RenderChildren(props.tabs[0].children);
    }
    return _jsx(_Fragment, {});
}
export function TabsHeader(props) {
    return (_jsx(Components.BtnGroup, { children: props.tabs.map(function (tab, index) {
            var _a;
            return (_jsx(Components.Button, { onClick: function () { return props.setSelected(tab.properties.id); }, isActive: ((_a = tab.properties) === null || _a === void 0 ? void 0 : _a.id) === props.selectedId, children: _jsx(Components.Title, __assign({}, tab.properties)) }, index));
        }) }));
}
export function TabsWidget(props) {
    return (_jsxs(Components.Widget, { children: [_jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Top, type: Components.WidgetSideType.Big, children: _jsxs(Components.Layout, { type: Components.LayoutType.HorizontalStack, children: [_jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Main, children: _jsx(TabsHeader, __assign({}, props)) }), _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: _jsx("h1", { children: Components.Text(props.title) }) })] }) }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Bottom, type: Components.WidgetSideType.Big, children: _jsxs(Components.Layout, { type: Components.LayoutType.HorizontalStack, children: [_jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Main }), _jsx(Components.LayoutArea, { name: Components.LayoutAreasNames.Stack.Side, children: props.extraBtns })] }) }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Content, children: _jsx(TabsContent, __assign({}, props), props.selectedId) })] }));
}
export function Tabs(component, index) {
    var _a = React.useState(''), selected = _a[0], setSelected = _a[1];
    var tabs = FIlterTabComponents(component);
    return (_jsx(TabsWidget, { tabs: tabs, selectedId: selected, setSelected: function (id) { return setSelected(id); }, title: Components.TitleText(component.properties) }));
}
export function SettingsDialogTabs(component, index) {
    var dialogCtx = Common.useSettingsDialog();
    var tabs = FIlterTabComponents(component);
    var closeBtn = (_jsx(Components.BtnGroup, { children: _jsx("button", { onClick: function () { return dialogCtx.setTab(Common.SettingsTabs.None); }, children: "Close" }) }));
    return (_jsx(TabsWidget, { tabs: tabs, selectedId: dialogCtx.tab, setSelected: function (id) { return dialogCtx.setTab(id); }, title: Components.TitleText(component.properties), extraBtns: closeBtn }));
}
function FIlterTabComponents(component) {
    var tabs = [];
    if (component.children) {
        for (var _i = 0, _a = component.children; _i < _a.length; _i++) {
            var child = _a[_i];
            if (child.type.toLowerCase() === 'tab') {
                tabs.push(child);
            }
            // todo fix this
            // if (child.type.toLowerCase() === 'if') {
            //     const nested = IfRender(child);
            //     for (const n of nested || []) {
            //         tabs.push(n);
            //     }
            // }
        }
    }
    return tabs;
}
export function TabsDebug(component, index) {
    function tab(id, group) {
        return {
            type: 'tab',
            properties: { id: id, title: 'Tab ' + id, group: group },
            children: [{ type: 'Title', properties: { text: 'Tab ' + id } }],
        };
    }
    var tabs = [
        tab('1', null),
        tab('2', null),
        tab('3.1', 'Grp 1'),
        tab('3.2', 'Grp 1'),
        tab('3.3', 'Grp 1'),
        tab('3.4', 'Grp 1'),
        tab('4.1', 'Grp 2'),
        tab('4.2', 'Grp 2'),
        tab('4.3', 'Grp 2'),
        tab('4.4', 'Grp 2'),
    ];
    var cmp = {
        type: 'tabs',
        properties: { title: 'Title' },
        children: tabs,
    };
    return Tabs(cmp, index);
}
