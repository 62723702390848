import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import { componentRegister } from '_componentRegister';
export function Content(props) {
    var overrideCtx = React.useContext(ContentOverrideContext);
    if (!props.component) {
        return _jsx(_Fragment, {});
    }
    var type = props.component.type.toLowerCase();
    if (type === 'text') {
        if (props.component.properties.text) {
            return (_jsx("span", { children: props.component.properties.text }, props.index));
        }
        return _jsx(_Fragment, {});
    }
    if (type === 'array') {
        return _jsx(_Fragment, { children: RenderChildren(props.component.children) });
    }
    var render = undefined;
    if (overrideCtx) {
        render = overrideCtx(type);
    }
    render = render || componentRegister(type);
    if (render) {
        return (_jsx(Common.ErrorBoundary, { errorGui: "Component ".concat(type, " cound not be rendered."), children: render(props.component, props.index) }));
    }
    if (Common.debugSettings.isDebug) {
        return _jsx("pre", { children: JSON.stringify(props.component, null, 4) });
    }
    return _jsx(_Fragment, {});
}
export var RenderChildren = function (components) {
    if (!components) {
        return null;
    }
    return (_jsx(_Fragment, { children: components.map(function (cmp, index) { return (_jsx(Content, { index: index, component: cmp }, index)); }) }));
};
export var ContentOverrideContext = React.createContext(null);
