var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Common from '_common';
import * as Components from '_components';
export function Overlay() {
    var overlayContext = Common.useOverlay();
    var overlay = overlayContext.overlay;
    if (overlay.type === Common.OverlayType.Notification) {
        return _jsx(_Fragment, { children: "TODO" });
    }
    if (overlay.type === Common.OverlayType.Error) {
        return _jsx(ErrorOverlay, __assign({}, overlay));
    }
    if (overlay.type === Common.OverlayType.Navigation) {
        return _jsx(MenuOverlay, { overlayContext: overlayContext });
    }
    return _jsx(_Fragment, {});
}
export function ErrorOverlay(props) {
    return (_jsx(Components.Modal, { open: true, children: _jsxs(Components.Widget, { children: [_jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Left, type: Components.WidgetSideType.Small }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Right, type: Components.WidgetSideType.Small }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Content, children: _jsx(Components.BigPanel, { children: _jsx("div", { className: "errorOverlay", children: _jsx("h1", { children: _jsx(_Fragment, { children: props.message }) }) }) }) })] }) }));
}
function MenuOverlay(props) {
    return (_jsx(Components.Modal, { open: true, onClose: function () {
            return props.overlayContext.setOverlay({
                type: Common.OverlayType.Null,
            });
        }, children: _jsxs(Components.Widget, { children: [_jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Top, type: Components.WidgetSideType.Big }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Bottom, type: Components.WidgetSideType.Big }), _jsx(Components.WidgetComponent, { side: Components.WidgetComponentType.Content, children: _jsx(Components.NavSideBar, {}) })] }) }));
}
