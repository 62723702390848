import { jsx as _jsx } from "react/jsx-runtime";
// https://github.com/material-icons/material-icons/tree/master/svg
// https://fonts.google.com/icons?icon.style=Outlined
export function CheckBox() {
    return _jsx("span", { className: "material-symbols-outlined", children: "check_box" });
}
export function CheckBoxBlank() {
    return (_jsx("span", { className: "material-symbols-outlined", children: "check_box_outline_blank" }));
}
export function IconClose() {
    return _jsx("span", { className: "material-symbols-outlined", children: "close" });
}
export function IconDone() {
    return _jsx("span", { className: "material-symbols-outlined", children: "check" });
}
export function IconSettings() {
    return _jsx("span", { className: "material-symbols-outlined", children: "settings" });
}
export function IconPerson() {
    return _jsx("span", { className: "material-symbols-outlined", children: "person" });
}
export function IconLanguage() {
    return _jsx("span", { className: "material-symbols-outlined", children: "language" });
}
export function IconInfo() {
    return _jsx("span", { className: "material-symbols-outlined", children: "info_i" });
}
