// extracted by mini-css-extract-plugin
var _1 = "src-components-common-Widget-module__append-animate";
var _2 = "src-components-common-Widget-module__content";
var _3 = "src-components-common-Widget-module__decoration";
var _4 = "src-components-common-Widget-module__gap-horizontal-large";
var _5 = "src-components-common-Widget-module__gap-horizontal-main";
var _6 = "src-components-common-Widget-module__gap-horizontal-none";
var _7 = "src-components-common-Widget-module__gap-horizontal-small";
var _8 = "src-components-common-Widget-module__gap-vertical-large";
var _9 = "src-components-common-Widget-module__gap-vertical-main";
var _a = "src-components-common-Widget-module__gap-vertical-none";
var _b = "src-components-common-Widget-module__gap-vertical-small";
var _c = "src-components-common-Widget-module__inner";
var _d = "src-components-common-Widget-module__side_bottom";
var _e = "src-components-common-Widget-module__side_left";
var _f = "src-components-common-Widget-module__side_right";
var _10 = "src-components-common-Widget-module__side_top";
var _11 = "src-components-common-Widget-module__variant_normal";
var _12 = "src-components-common-Widget-module__variant_small";
export { _1 as "append-animate", _2 as "content", _3 as "decoration", _4 as "gap-horizontal-large", _5 as "gap-horizontal-main", _6 as "gap-horizontal-none", _7 as "gap-horizontal-small", _8 as "gap-vertical-large", _9 as "gap-vertical-main", _a as "gap-vertical-none", _b as "gap-vertical-small", _c as "inner", _d as "side_bottom", _e as "side_left", _f as "side_right", _10 as "side_top", _11 as "variant_normal", _12 as "variant_small" }

    if(module.hot) {
      (function() {
        var localsJsonString = "{\"append-animate\":\"src-components-common-Widget-module__append-animate\",\"content\":\"src-components-common-Widget-module__content\",\"decoration\":\"src-components-common-Widget-module__decoration\",\"gap-horizontal-large\":\"src-components-common-Widget-module__gap-horizontal-large\",\"gap-horizontal-main\":\"src-components-common-Widget-module__gap-horizontal-main\",\"gap-horizontal-none\":\"src-components-common-Widget-module__gap-horizontal-none\",\"gap-horizontal-small\":\"src-components-common-Widget-module__gap-horizontal-small\",\"gap-vertical-large\":\"src-components-common-Widget-module__gap-vertical-large\",\"gap-vertical-main\":\"src-components-common-Widget-module__gap-vertical-main\",\"gap-vertical-none\":\"src-components-common-Widget-module__gap-vertical-none\",\"gap-vertical-small\":\"src-components-common-Widget-module__gap-vertical-small\",\"inner\":\"src-components-common-Widget-module__inner\",\"side_bottom\":\"src-components-common-Widget-module__side_bottom\",\"side_left\":\"src-components-common-Widget-module__side_left\",\"side_right\":\"src-components-common-Widget-module__side_right\",\"side_top\":\"src-components-common-Widget-module__side_top\",\"variant_normal\":\"src-components-common-Widget-module__variant_normal\",\"variant_small\":\"src-components-common-Widget-module__variant_small\"}";
        // 1728929521881
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  