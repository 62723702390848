import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import * as Common from '_common';
import * as Components from '_components';
import * as Styles from './Navigation.module.scss';
import { appModules } from '_appModuleRegister';
export function NavSideBar() {
    var user = Common.useUser();
    var selectedModule = React.useContext(Common.SelectedAppModuleContext);
    function mapAppModuleLink(module) {
        if (Common.userHaveRole(user, module.role)) {
            return (_jsxs(React.Fragment, { children: [_jsx(Components.ButtonLink, { to: '/' + module.id, isActive: module.id === selectedModule, children: _jsx(Components.MLT, { text: module.name }) }), module.id === selectedModule && (_jsx(Common.Placeholder, { name: "ModuleNav" }))] }, module.id));
        }
    }
    // TODO add floating version
    return (_jsx("div", { className: Styles.nav, children: _jsxs(Components.BtnGroup, { children: [appModules.map(mapAppModuleLink), _jsx(Components.ButtonLink, { to: '/Debug/Index', children: "Debug" })] }) }));
}
export function DebugNavSideBar() {
    return _jsx(NavSideBar, {});
}
