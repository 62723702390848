import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { LanguageProvider } from './LanguageProvider';
import { ResponsiveProvider } from './ResponsiveProvider';
import { SpaContextProvider } from './SpaContextProvider';
import { ThemeProvider } from './ThemeProvider';
import * as Types from './Types';
import { UserProvider } from './UserProvider';
import { ScreenStateProvider } from './ScreenStateProvider';
function createInitialState() {
    return {
        appState: Types.AppLoadingState.Null,
        loadingState: [],
    };
}
var AppStateContext = React.createContext(createInitialState());
AppStateContext.displayName = 'AppStateContext';
export function AppStateManager(props) {
    return (_jsx(ResponsiveProvider, { children: _jsx(ThemeProvider, { children: _jsx(ScreenStateProvider, { children: _jsx(UserProvider, { children: _jsx(LanguageProvider, { children: _jsx(SpaContextProvider, { children: props.children }) }) }) }) }) }));
}
