import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
export function Refresh(props) {
    var _a = React.useState(new Date()), setState = _a[1];
    function tick() {
        setState(new Date());
    }
    React.useEffect(function () {
        var timer = setInterval(function () { return tick(); }, props.afterMs);
        return function () {
            clearInterval(timer);
        };
    }, [props.afterMs]);
    if (!props.children) {
        return _jsx(_Fragment, {});
    }
    if (typeof props.children === 'function') {
        return _jsx(_Fragment, { children: props.children() });
    }
    else {
        return _jsx(_Fragment, { children: props.children });
    }
}
