import { jsx as _jsx } from "react/jsx-runtime";
import * as Utils from '_utils';
import * as Styles from './Panels.module.scss';
export function BigPanel(props) {
    return PanelBase(Styles.bigPanel, props);
}
function PanelBase(type, props) {
    var _a;
    var classes = Utils.classNames((_a = {},
        _a[type] = true,
        _a));
    return _jsx("div", { className: classes, children: props.children });
}
