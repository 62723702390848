var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//import { getLanguage } from "./Language";
import { debugSettings } from '_debug';
var Ajax = /** @class */ (function () {
    function Ajax() {
    }
    // public static async get(url: string): Promise<any> {
    //     const text = await this.getRaw(url);
    //     return text ? JSON.parse(text) : undefined;
    // }
    Ajax.get = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var text;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRaw(url)];
                    case 1:
                        text = _a.sent();
                        return [2 /*return*/, text ? JSON.parse(text) : undefined];
                }
            });
        });
    };
    Ajax.getRaw = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.doFetchRaw(url, 'GET')];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Ajax.post = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            var body, text;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = data && JSON.stringify(data);
                        return [4 /*yield*/, this.postRaw(url, body)];
                    case 1:
                        text = _a.sent();
                        return [2 /*return*/, text ? JSON.parse(text) : undefined];
                }
            });
        });
    };
    Ajax.put = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            var body, text;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = data && JSON.stringify(data);
                        return [4 /*yield*/, this.doFetchRaw(url, 'PUT', body)];
                    case 1:
                        text = _a.sent();
                        return [2 /*return*/, text ? JSON.parse(text) : undefined];
                }
            });
        });
    };
    Ajax.postRaw = function (url, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.doFetchRaw(url, 'POST', data)];
            });
        });
    };
    Ajax.doFetchRaw = function (url, method, body) {
        return __awaiter(this, void 0, void 0, function () {
            var response, appVersion, detail, error, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, fetch(debugSettings.apiRoot + url, {
                                method: method,
                                mode: 'same-origin',
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    //"Lng": getLanguage()
                                },
                                credentials: 'same-origin',
                                body: body,
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) return [3 /*break*/, 3];
                        appVersion = response.headers.get('App-Version');
                        if (appVersion) {
                            document.appVersion = appVersion;
                        }
                        return [4 /*yield*/, response.text()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [4 /*yield*/, response.text()];
                    case 4:
                        detail = _a.sent();
                        error = new Error('Request failed');
                        if (detail.startsWith('{')) {
                            detail = JSON.parse(detail);
                        }
                        error.status = response.status;
                        error.detail = detail;
                        return [2 /*return*/, Promise.reject(error)];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _a.sent();
                        console.error(error_1, debugSettings.apiRoot + url);
                        return [2 /*return*/, Promise.reject(new Error('Request failed'))];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    return Ajax;
}());
export { Ajax };
