var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DebugPage, useOneOf, useToggles } from './../../app/DebugPage';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as Common from '_common';
import * as Components from '_components';
import * as Utils from '_utils';
import * as Styles from './Button.module.scss';
function getClasses(props, isLink) {
    var _a;
    if (isLink === void 0) { isLink = false; }
    var classNames = (_a = {},
        _a[Styles.btn] = !isLink,
        _a[Styles.primary] = props.isPrimary || false,
        _a[Styles.active] = props.isActive || false,
        _a[Styles.not_active] = props.isActive === false,
        _a);
    classNames = Common.WithCommonProps(props, classNames);
    classNames = Components.WithTileProps(props, classNames);
    return Utils.classNames(classNames);
}
export function Button(props) {
    var classes = getClasses(props);
    var content = props.children;
    if (props.icon) {
        content = (_jsxs(_Fragment, { children: [_jsx("span", { className: Styles.icon, children: props.icon }), props.children] }));
    }
    return (_jsx("button", { className: classes, disabled: props.isDisabled, onClick: props.onClick, children: content }));
}
export function SubmitButton(props) {
    var classes = getClasses(props);
    var content = props.children;
    if (props.icon) {
        content = (_jsxs(_Fragment, { children: [_jsx("span", { className: Styles.icon, children: props.icon }), props.children] }));
    }
    return (_jsx("button", { type: "submit", className: classes, disabled: props.isDisabled, onClick: props.onClick, children: content }));
}
export function ButtonLink(props) {
    var _a;
    var classes = getClasses(props);
    if (!props.to) {
        return _jsx(_Fragment, { children: props.children });
    }
    if ((_a = props.to) === null || _a === void 0 ? void 0 : _a.startsWith('/')) {
        return (_jsx(NavLink, { className: classes, to: props.to, children: props.children }));
    }
    else {
        return (_jsx("a", { className: classes, href: props.to, children: props.children }));
    }
}
export function Link(props) {
    var _a;
    var classes = getClasses(props, true);
    if (!props.to) {
        return _jsx(_Fragment, { children: props.children });
    }
    if ((_a = props.to) === null || _a === void 0 ? void 0 : _a.startsWith('/')) {
        return (_jsx(NavLink, { className: classes, to: props.to, children: props.children }));
    }
    else {
        return (_jsx("a", { className: classes, href: props.to, children: props.children }));
    }
}
export function NavButton(props) {
    var navigate = useNavigate();
    function onClick() {
        if (props.target.includes('/')) {
            navigate(props.target);
        }
        else {
            navigate('/Page/' + props.target);
        }
    }
    return (_jsx(Button, __assign({}, props, { onClick: onClick, children: props.children })));
}
export function NavButtonRender(component, index) {
    return (_jsxs(NavButton, __assign({}, component.properties, { children: [' ', _jsx(Components.SimpleComponentContent, { component: component })] }), index));
}
export function Toggle(props) {
    function toggle() {
        if (props.onChange) {
            props.onChange(!props.checked);
        }
    }
    return (_jsx(Button, __assign({}, props, { isActive: props.checked, onClick: toggle, children: props.children })));
}
function DummyToggle(props) {
    var _a = React.useState(false), value = _a[0], setValue = _a[1];
    return (_jsx(Toggle, __assign({}, props, { checked: value, onChange: setValue, children: props.children })));
}
export function useToggle(props) {
    var _a = React.useState(props.checked || false), value = _a[0], setValue = _a[1];
    var cmp = (_jsx(Toggle, __assign({}, props, { checked: value, onChange: setValue, children: props.children })));
    return [value, cmp];
}
export var BtnGroupType;
(function (BtnGroupType) {
    BtnGroupType[BtnGroupType["Horizontal"] = 0] = "Horizontal";
    BtnGroupType[BtnGroupType["Vertical"] = 1] = "Vertical";
    BtnGroupType[BtnGroupType["Grid"] = 2] = "Grid";
})(BtnGroupType || (BtnGroupType = {}));
export function BtnGroup(props) {
    var _a;
    var classes = Utils.classNames((_a = {},
        _a[Styles.grp_horizontal] = props.type === BtnGroupType.Horizontal,
        _a[Styles.grp_vertical] = props.type === BtnGroupType.Vertical,
        _a[Styles.grp_grid] = props.type === BtnGroupType.Grid,
        _a[Styles.grp_small] = props.small,
        _a));
    return (_jsx(Common.Component, { name: 'btn-group', className: classes, children: props.children }));
}
export function ButtonsDebug(component, index) {
    var _a = useToggles({
        isPrimary: false,
        isActive: false,
        isDisabled: false,
    }), toggles = _a[0], togglesControls = _a[1];
    var _b = useOneOf([
        'None',
        'Horizontal',
        'Vertical',
        'Grid',
    ]), group = _b[0], groupControls = _b[1];
    var _c = useOneOf(['Simple', 'Toggle', 'Complex']), type = _c[0], typeControls = _c[1];
    var controls = (_jsxs(_Fragment, { children: [togglesControls, _jsx("h3", { children: "Group" }), groupControls, _jsx("h3", { children: "Type" }), typeControls] }));
    function createButton(text) {
        if (type === 'Simple') {
            return _jsx(Button, __assign({}, toggles, { children: text }));
        }
        if (type === 'Toggle') {
            return _jsx(DummyToggle, __assign({}, toggles, { children: text }));
        }
    }
    var btns = (_jsxs(_Fragment, { children: [createButton('Btn 1'), createButton('Btn 22'), createButton('Btn 333'), createButton('Btn 4444')] }));
    function createGroup() {
        if (group === 'None') {
            return btns;
        }
        function getGrpType() {
            switch (group) {
                case 'Horizontal':
                    return BtnGroupType.Horizontal;
                case 'Vertical':
                    return BtnGroupType.Vertical;
                case 'Grid':
                    return BtnGroupType.Grid;
            }
        }
        var type = getGrpType();
        return _jsx(BtnGroup, { type: type, children: btns });
    }
    return _jsx(DebugPage, { controls: controls, children: createGroup() });
}
export function Gap() {
    return _jsx("span", { className: Styles.gap });
}
