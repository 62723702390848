import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { LocalText, useLanguage } from '_common';
export function MLT(props) {
    var lang = useLanguage();
    var mlt;
    if ('text' in props) {
        mlt = props.text;
    }
    else {
        mlt = props;
    }
    return _jsx(_Fragment, { children: LocalText(mlt, lang) || null });
}
export function BooleanText(props) {
    if (props.value) {
        return _jsx(MLT, { cs: "Ano", en: "Yes" });
    }
    else {
        return _jsx(MLT, { cs: "Ne", en: "No" });
    }
}
