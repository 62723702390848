import { jsx as _jsx } from "react/jsx-runtime";
import { SimpleComponentContent } from '_components';
export function textComponentRegister(type) {
    switch (type) {
        case 'bold':
            return Bold;
        case 'italic':
            return Italic;
        case 'underline':
            return Underlined;
    }
}
export function Bold(component, index) {
    return (_jsx("b", { children: _jsx(SimpleComponentContent, { component: component }) }));
}
export function Italic(component, index) {
    return (_jsx("i", { children: _jsx(SimpleComponentContent, { component: component }) }));
}
export function Underlined(component, index) {
    return (_jsx("u", { children: _jsx(SimpleComponentContent, { component: component }) }));
}
