var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as Common from '_common';
import * as Components from '_components';
import * as Utils from '_utils';
export function DashboardButton(props) {
    var dsContext = Components.useDashboardContext();
    var setMode = (dsContext === null || dsContext === void 0 ? void 0 : dsContext.setMode) || (function () { });
    return (_jsx(Components.Button, { tileSize: props.size || Components.TileSize.SmallLong, isActive: props.isActive, onClick: function () { return setMode(props.id); }, children: props.children }));
}
export function RenderDashboardRoomButton(component, index) {
    return (_jsx(DashboardRoomButton, { name: component.properties.name, setMode: function () { } }));
}
export function DashboardRoomButton(props) {
    var context = Common.useSpaContext();
    var roomInfo = (context === null || context === void 0 ? void 0 : context.state) ? context === null || context === void 0 ? void 0 : context.state[props.name] : {};
    function isWindowOpened(windowClosed) {
        if (windowClosed === undefined || windowClosed === null) {
            return false;
        }
        return !Utils.toBool(windowClosed);
    }
    function getDetails() {
        var _a, _b, _c, _d;
        var details = [];
        if (roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.temp) {
            details.push(_jsx("div", { children: formatTemperature(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.temp) }, "1"));
        }
        if ((roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.co2) >= 800) {
            details.push(_jsxs("div", { children: ["CO2: ", formatNumber(roomInfo.co2), " ppm"] }, "2"));
        }
        if ((roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.humidity) >= 80) {
            details.push(_jsxs("div", { children: ["Humidity: ", formatNumber(roomInfo.humidity), "%"] }, "3"));
        }
        if (details.length < 3 && isWindowOpened((_a = roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.window) === null || _a === void 0 ? void 0 : _a.closed)) {
            details.push(_jsx("div", { children: _jsx(Components.MLT, { en: "Window opened", cs: "Okno je otev\u0159en\u00E9" }) }, "4"));
        }
        if (details.length < 3 &&
            isWindowOpened((_b = roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.northWindow) === null || _b === void 0 ? void 0 : _b.closed)) {
            details.push(_jsx("div", { children: _jsx(Components.MLT, { en: "North window opened", cs: "Severn\u00ED okno je otev\u0159en\u00E9" }) }, "5"));
        }
        if (details.length < 3 &&
            isWindowOpened((_c = roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.southWindow) === null || _c === void 0 ? void 0 : _c.closed)) {
            details.push(_jsx("div", { children: _jsx(Components.MLT, { en: "South window opened", cs: "Ji\u017En\u00ED okno je otev\u0159en\u00E9" }) }, "6"));
        }
        if (details.length < 3 &&
            isWindowOpened((_d = roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.kitchenWindow) === null || _d === void 0 ? void 0 : _d.closed)) {
            details.push(_jsx("div", { children: _jsx(Components.MLT, { en: "Kitchen window opened", cs: "Kuchy\u0148sk\u00E9 okno je otev\u0159en\u00E9" }) }, "7"));
        }
        return details;
    }
    var details = getDetails();
    function getTileSize() {
        if (details.length === 0) {
            return Components.TileSize.SmallLong;
        }
        return details.length > 1
            ? Components.TileSize.Long
            : Components.TileSize.LongThin;
    }
    return (_jsx(DashboardButton, { id: props.name, size: getTileSize(), children: _jsxs("div", { children: [_jsx("h1", { children: GetRoomName(props.name) }), details] }) }));
}
function formatTemperature(temp) {
    return formatNumber(temp) + '°C';
}
function formatNumber(value) {
    if (value === undefined) {
        return 'N/A';
    }
    if (typeof value === 'string') {
        value = Number.parseFloat(value);
    }
    return Intl.NumberFormat().format(value);
}
export function RenderDashboardRoomFull(component, index) {
    return _jsx(RoomFullWidtget, { name: component.properties.name });
}
export function RoomFullWidtget(props) {
    var context = Common.useSpaContext();
    var roomInfo = (context === null || context === void 0 ? void 0 : context.state) ? context === null || context === void 0 ? void 0 : context.state[props.name] : {};
    function formatBlind(blind, windowName) {
        if (!blind) {
            return _jsx(_Fragment, {});
        }
        var signs = [{ value: Number.parseInt(blind.pos1), text: '' }];
        var blindName = getBlindName(props.name, windowName);
        function sendCommand(cmd) {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, Utils.Ajax.post('/house_api/command/' + blindName + '/' + cmd)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            // TODO what now?
                            console.error(error_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        return (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx(Components.Progress, { type: Components.ProgressType.Blinds, value: blind.pos, max: blind.max, customSigns: signs }), _jsxs(Components.BtnGroup, { type: Components.BtnGroupType.Horizontal, small: true, children: [_jsx(Components.Button, { onClick: function () {
                                sendCommand('open');
                            }, children: "Open" }), _jsx(Components.Button, { onClick: function () {
                                sendCommand('ToPos1');
                            }, children: "Pos 1" }), _jsx(Components.Button, { onClick: function () {
                                sendCommand('close');
                            }, children: "Close" }), _jsx(Components.Button, { onClick: function () {
                                sendCommand('stop');
                            }, children: "Stop" })] })] }));
    }
    function formatWindow(window, windowName, title) {
        if (!window) {
            return _jsx(_Fragment, {});
        }
        return (_jsx(_Fragment, { children: _jsxs(Components.Property, { title: _jsx(Components.MLT, { text: title }), children: [window.closed ? (_jsx(Components.MLT, { cs: "Zav\u0159eno", en: "Closed" })) : (_jsx(Components.MLT, { cs: "Otev\u0159eno", en: "Opened" })), formatBlind(window.blind, windowName)] }) }));
    }
    function formatLights() {
        if (props.name !== 'bedRoom') {
            return _jsx(_Fragment, {});
        }
        function getLight(light, id, name) {
            var _a;
            return (_jsx(Components.Property, { title: _jsx(Components.MLT, { text: name }), children: _jsxs(Components.BtnGroup, { type: Components.BtnGroupType.Horizontal, children: [_jsx(Components.Button, { onClick: function () {
                                Utils.Ajax.post("/house_api/command/".concat(id, "/off"));
                            }, children: "Off" }), _jsx(Components.Progress, { type: Components.ProgressType.Value, max: 256, value: (_a = light === null || light === void 0 ? void 0 : light.value) !== null && _a !== void 0 ? _a : 0, onClick: function (value) {
                                Utils.Ajax.post("/house_api/command/".concat(id, "/").concat(value));
                            } }), _jsx(Components.Button, { onClick: function () {
                                Utils.Ajax.post("/house_api/command/".concat(id, "/on"));
                            }, children: "On" })] }) }));
        }
        return (_jsxs(_Fragment, { children: [getLight(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.light1, 'Light1', '1'), getLight(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.light2, 'Light2', '2')] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: GetRoomName(props.name) }), _jsx(Components.Property, { title: _jsx(Components.MLT, { cs: "Teplota", en: "Temperature" }), children: formatTemperature(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.temp) }), _jsxs(Components.Property, { title: _jsx(Components.MLT, { cs: "Vlhkost", en: "Humidity" }), children: [formatNumber(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.rh), "%"] }), _jsxs(Components.Property, { title: _jsx(Components.MLT, { cs: "CO2", en: "CO2" }), children: [formatNumber(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.co2), " ppm"] }), formatLights(), formatWindow(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.window, 'window', {
                cs: 'Okno',
                en: 'Window',
            }), formatWindow(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.northWindow, 'northWindow', {
                cs: 'Severní okno',
                en: 'North window',
            }), formatWindow(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.southWindow, 'southWindow', {
                cs: 'Jižní okno',
                en: 'South window',
            }), formatWindow(roomInfo === null || roomInfo === void 0 ? void 0 : roomInfo.kitchenWindow, 'kitchenWindow', {
                cs: 'Kuchyňské okno',
                en: 'Kitchen window',
            })] }));
}
export function GetRoomName(name) {
    switch (name) {
        case 'livingRoom':
            return _jsx(Components.MLT, { en: "Living room", cs: "Ob\u00FDv\u00E1k" });
        case 'bedRoom':
            return _jsx(Components.MLT, { en: "Bedroom", cs: "Lo\u017Enice" });
        case 'office':
            return _jsx(Components.MLT, { en: "Office", cs: "Kancel\u00E1\u0159" });
        case 'laundry':
            return _jsx(Components.MLT, { en: "Laundry", cs: "Pr\u00E1delna" });
        case 'bathroom':
            return _jsx(Components.MLT, { en: "Bathroom", cs: "Koupelna" });
        case 'vestibule':
            return _jsx(Components.MLT, { en: "Vestibule", cs: "P\u0159eds\u00ED\u0148" });
        case 'northRoom':
            return _jsx(Components.MLT, { en: "North room", cs: "Severn\u00ED pokoj" });
        case 'southRoom':
            return _jsx(Components.MLT, { en: "South room", cs: "Ji\u017En\u00ED pokoj" });
        case 'upperHallway':
            return _jsx(Components.MLT, { en: "Upper hallway", cs: "Horn\u00ED chodba" });
        default:
            return name;
    }
}
function getBlindName(roomName, windowName) {
    switch (roomName) {
        case 'office':
            return 'officeBlind';
        case 'vestibule':
            return 'vestibuleBlind';
        case 'northRoom':
            return 'northRoomBlind';
        case 'southRoom':
            return 'southRoomBlind';
        case 'upperHallway':
            return 'upperHallWayBlind';
        case 'livingRoom':
            switch (windowName) {
                case 'southWindow':
                    return 'livingRoomSouthBlind';
                case 'northWindow':
                    return 'livingRoomNorthBlind';
                case 'kitchenWindow':
                    return 'kitchenBlind';
                default:
                    return null;
            }
        default:
            return null;
    }
}
export function RenderHotWaterPumpButtonRender(component, index) {
    return _jsx(HotWaterPumpButton, {});
}
function HotWaterPumpButton() {
    var _a;
    var context = Common.useSpaContext();
    var hotWaterPump = ((_a = context === null || context === void 0 ? void 0 : context.state) === null || _a === void 0 ? void 0 : _a.hotWaterPump) || {};
    function getDetails() {
        if (Utils.toBool(hotWaterPump.on)) {
            return (_jsxs(_Fragment, { children: [_jsx("progress", { value: hotWaterPump.offIn, max: hotWaterPump.cfg }), hotWaterPump.offIn] }));
        }
        else {
            return (_jsx(Components.Refresh, { afterMs: 500, children: _jsx(Components.MLT, { text: Utils.since(Utils.toDate(hotWaterPump.lOff)) }) }));
        }
    }
    function toggle() {
        if (Utils.toBool(hotWaterPump.on)) {
            // turn off
            Utils.Ajax.post('/house_api/command/hotWaterPump/stop');
        }
        else {
            // turn on
            Utils.Ajax.post('/house_api/command/hotWaterPump/start');
        }
    }
    return (_jsx(Components.Button, { tileSize: Components.TileSize.LongThin, onClick: function () { return toggle(); }, children: _jsxs("div", { children: [_jsx("h1", { children: _jsx(Components.MLT, { en: "Hot water", cs: "Tepl\u00E1 voda" }) }), getDetails()] }) }));
}
