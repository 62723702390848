export var AppLoadingState;
(function (AppLoadingState) {
    AppLoadingState[AppLoadingState["Null"] = 0] = "Null";
    AppLoadingState[AppLoadingState["LoadingScreen"] = 1] = "LoadingScreen";
    AppLoadingState[AppLoadingState["AppScreen"] = 2] = "AppScreen";
    AppLoadingState[AppLoadingState["ErrorScreen"] = 3] = "ErrorScreen";
})(AppLoadingState || (AppLoadingState = {}));
export var AsyncDataStatus;
(function (AsyncDataStatus) {
    AsyncDataStatus[AsyncDataStatus["Null"] = 0] = "Null";
    AsyncDataStatus[AsyncDataStatus["Loading"] = 1] = "Loading";
    AsyncDataStatus[AsyncDataStatus["Available"] = 2] = "Available";
    AsyncDataStatus[AsyncDataStatus["Failed"] = 3] = "Failed";
})(AsyncDataStatus || (AsyncDataStatus = {}));
export var SettingsTabs = {
    None: 'none',
    Theme: 'theme',
    Language: 'language',
    Login: 'login',
    UserInfo: 'profile',
    About: 'about',
};
// TODO MLT component shoudl use this
export function LocalText(text, locale) {
    if (!text) {
        return undefined;
    }
    if (typeof text === 'string') {
        return text;
    }
    if (locale === 'cs' && text.cs) {
        return text.cs;
    }
    if (locale === 'en' && text.en) {
        return text.en;
    }
    return text.en || text.cs || text.default || undefined;
}
export var OverlayType;
(function (OverlayType) {
    OverlayType[OverlayType["Null"] = 0] = "Null";
    OverlayType[OverlayType["Error"] = 1] = "Error";
    OverlayType[OverlayType["Notification"] = 2] = "Notification";
    OverlayType[OverlayType["Navigation"] = 3] = "Navigation";
})(OverlayType || (OverlayType = {}));
