import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { useEffect } from 'react';
var ResponsiveContext = React.createContext({
    sm: false,
    md: false,
    lg: false,
    xl: false,
    height: false,
    portrait: false,
    touch: false,
});
ResponsiveContext.displayName = 'ResponsiveContext';
export function ResponsiveProvider(props) {
    var _a = React.useState(getResponsive()), state = _a[0], setState = _a[1];
    var refresh = function () {
        console.info('Refreshing responsive state');
        setState(getResponsive());
    };
    useEffect(function () {
        smQuery().addEventListener('change', refresh);
        mdQuery().addEventListener('change', refresh);
        lgQuery().addEventListener('change', refresh);
        xlQuery().addEventListener('change', refresh);
        heightQuery().addEventListener('change', refresh);
        portraitQuery().addEventListener('change', refresh);
    }, []);
    return (_jsx(ResponsiveContext.Provider, { value: state, children: props.children }));
}
export function useResponsive() {
    var appState = React.useContext(ResponsiveContext);
    return appState;
}
export function getResponsive() {
    return {
        sm: smQuery().matches,
        md: mdQuery().matches,
        lg: lgQuery().matches,
        xl: xlQuery().matches,
        height: heightQuery().matches,
        portrait: portraitQuery().matches,
        touch: touchQuery().matches,
    };
}
var smQuery = function () { return window.matchMedia('(min-width: 576px)'); };
var mdQuery = function () { return window.matchMedia('(min-width: 768px)'); };
var lgQuery = function () { return window.matchMedia('(min-width: 992px)'); };
var xlQuery = function () { return window.matchMedia('(min-width: 1200px)'); };
var heightQuery = function () { return window.matchMedia('(min-height: 700px)'); };
var portraitQuery = function () { return window.matchMedia('(orientation: portrait)'); };
var touchQuery = function () { return window.matchMedia('(pointer: coarse)'); };
