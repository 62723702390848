export var debugSettings = {
    isDebug: false,
    apiRoot: '',
};
export function loadDebugSettings() {
    var loadedDebugSettings = localStorage.getItem('debugSettings');
    if (loadedDebugSettings) {
        debugSettings = JSON.parse(loadedDebugSettings);
    }
    else {
        localStorage.setItem('debugSettings', JSON.stringify(debugSettings));
    }
}
loadDebugSettings();
