// extracted by mini-css-extract-plugin
var _1 = "src-components-common-Layout-module__area_main";
var _2 = "src-components-common-Layout-module__area_side";
var _3 = "src-components-common-Layout-module__area_top";
var _4 = "src-components-common-Layout-module__bottom";
var _5 = "src-components-common-Layout-module__center";
var _6 = "src-components-common-Layout-module__content";
var _7 = "src-components-common-Layout-module__dock_panel";
var _8 = "src-components-common-Layout-module__horizontal_stack";
var _9 = "src-components-common-Layout-module__left";
var _a = "src-components-common-Layout-module__main";
var _b = "src-components-common-Layout-module__minimal";
var _c = "src-components-common-Layout-module__normal";
var _d = "src-components-common-Layout-module__right";
var _e = "src-components-common-Layout-module__top";
var _f = "src-components-common-Layout-module__top_widgets";
var _10 = "src-components-common-Layout-module__vertical_stack";
export { _1 as "area_main", _2 as "area_side", _3 as "area_top", _4 as "bottom", _5 as "center", _6 as "content", _7 as "dock_panel", _8 as "horizontal_stack", _9 as "left", _a as "main", _b as "minimal", _c as "normal", _d as "right", _e as "top", _f as "top_widgets", _10 as "vertical_stack" }

    if(module.hot) {
      (function() {
        var localsJsonString = "{\"area_main\":\"src-components-common-Layout-module__area_main\",\"area_side\":\"src-components-common-Layout-module__area_side\",\"area_top\":\"src-components-common-Layout-module__area_top\",\"bottom\":\"src-components-common-Layout-module__bottom\",\"center\":\"src-components-common-Layout-module__center\",\"content\":\"src-components-common-Layout-module__content\",\"dock_panel\":\"src-components-common-Layout-module__dock_panel\",\"horizontal_stack\":\"src-components-common-Layout-module__horizontal_stack\",\"left\":\"src-components-common-Layout-module__left\",\"main\":\"src-components-common-Layout-module__main\",\"minimal\":\"src-components-common-Layout-module__minimal\",\"normal\":\"src-components-common-Layout-module__normal\",\"right\":\"src-components-common-Layout-module__right\",\"top\":\"src-components-common-Layout-module__top\",\"top_widgets\":\"src-components-common-Layout-module__top_widgets\",\"vertical_stack\":\"src-components-common-Layout-module__vertical_stack\"}";
        // 1728929521861
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  