import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes, useParams, useLocation, Navigate, } from 'react-router-dom';
import * as Common from '_common';
import * as Components from '_components';
import { DebugScreen } from './DebugPage';
import { PageLayoutController } from './PageLayoutController';
import { appModules } from '_appModuleRegister';
export function AppScreen() {
    var user = Common.useUser();
    function mapAppModuleRoute(module) {
        if (Common.userHaveRole(user, module.role)) {
            return (_jsx(Route, { path: "/".concat(module.id, "/*"), element: _jsx(ShowModule, { module: module }) }, module.id));
        }
    }
    var indexModule = appModules.find(function (m) { return m.id === 'Index'; });
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", index: true, element: _jsx(ShowModule, { module: indexModule }) }), appModules.map(mapAppModuleRoute), _jsx(Route, { path: "/Debug/:component", element: _jsx(DebugScreenByRoute, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }));
}
function ShowModule(props) {
    var user = Common.useUser();
    if (!Common.userHaveRole(user, props.module.role)) {
        return _jsx(Navigate, { to: "/", replace: true });
    }
    var layout = props.module.preferedLayout;
    var inner = null;
    if (layout === Common.AppModulePreferedLayout.Minimal) {
        inner = (_jsx(PageLayoutController, { preferMinimal: true, children: _jsx(props.module.components.module, {}) }));
    }
    else if (layout === Common.AppModulePreferedLayout.None) {
        inner = _jsx(props.module.components.module, {});
    }
    else {
        inner = (_jsx(PageLayoutController, { children: _jsx(props.module.components.module, {}) }));
    }
    return (_jsx(Common.SelectedAppModuleContext.Provider, { value: props.module.id, children: inner }));
}
function DebugScreenByRoute() {
    var component = useParams().component;
    return _jsx(DebugScreen, { cmp: component });
}
export function AppModule() {
    var params = useParams();
    var route = useLocation();
    return (_jsxs("div", { children: [_jsx("h1", { children: "App Module" }), _jsx("pre", { children: JSON.stringify(params) }), _jsx("pre", { children: JSON.stringify(route) })] }));
}
export function TestModule1() {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "", element: _jsx(AppModule, {}) }), _jsx(Route, { path: "/t1", element: _jsx(AppModule, {}) }), _jsx(Route, { path: "/t2", element: _jsx(AppModule, {}) }), _jsx(Route, { path: "/t3/:id/:command", element: _jsx(AppModule, {}) })] }));
}
export function TestModule1Nav() {
    return (_jsxs("ul", { children: [_jsx("li", { children: _jsx(Components.ButtonLink, { to: '/Test1', children: "Home" }) }), _jsx("li", { children: _jsx(Components.ButtonLink, { to: '/Test1/t1', children: "Test 1" }) }), _jsx("li", { children: _jsx(Components.ButtonLink, { to: '/Test1/t2', children: "Test 2" }) }), _jsx("li", { children: _jsx(Components.ButtonLink, { to: '/Test1/t3/1/info', children: "Test 3" }) })] }));
}
